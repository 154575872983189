<template>
  <div role="group" class="mb-2">
    <b-row>
      <b-col class="col-auto" style="padding-right: 5px!important;">
        <label class="label-for-input text-secondary" v-if="labelInput">{{ labelInput }} </label>
      </b-col>
      <b-col v-if="required" align="left" class="col-2" style="padding-left: 5px!important;">
        <strong v-if="requireLabel || required" style="color: red">*</strong>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-textarea :rows="rows" :max-rows="maxRows" :class="`input-area-text-box ${className} ${error}`"
                         :id="inputId" :state="state" :v-model="value" :type="type" :placeholder="placeholder"
                         :disabled="disabledType"
                         autocomplete="off" :value="value" @input="updateValue"></b-form-textarea>
        <input-emoji-component v-if="!disabledType" @insert="insert"></input-emoji-component>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12" v-if="trackingTwoProId && tempMainLink && tempDomain">
        <p class="m-0 text-left" style="color: red">{{ urlTrackingError }}</p>
      </b-col>
      <b-col :lg="trackingTwoProId && tempMainLink && tempDomain ? '6':'12'" md="12" sm="12" align="end">
        <span style="font-size: 12px">
          {{ $t("messageText.characters") }} :
        </span>
        <span class="text-primary font-weight-700">
          {{ charLeft }} / {{ maxChar }} ({{ $t('common.' + language) }})
        </span>
        &nbsp;
        <span style="font-size: 12px">{{ $t("messageText.calculated_as_Number_of_sms") }} :</span>
        <span class="text-primary font-weight-700">
          {{ smsCount }}
        </span>
      </b-col>
      <!--      URL tracking2PRO   -->
      <b-col lg="12" md="12" sm="12" class="mb-3" v-if="trackingTwoProId && tempMainLink && tempDomain">
        <div style="width: 100%;">
          <label style="font-size: 15px; width: 100%" class="label-select text-secondary text-left">
            URL_Tracking </label>
          <div class="font-weight-bold d-flex justify-content-between custom-badge align-items-center"
               id="input-message-custom-badge">
            <p class="m-0 custom-badge--detail" id="custom-badge-detail" @mouseover="checkWidth('custom-badge-detail')"
               @mouseleave="maxTooltip=false">
              {{ tempMainLink }}
            </p>
            <i class="fas fa-window-close" style="color: red; cursor: pointer" @click="removeURLTracking"/>
            <b-tooltip target="custom-badge-detail" placement="top" v-if="maxTooltip">
              {{ tempMainLink }}
            </b-tooltip>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col lg="12" md="12" sm="12">
        <b-button v-if="has_current_package && checkPermission('list', 'action', 'url-trackings')" variant="outline"
                  @click="shortLinkList()">
          <span class="mx-2">
            <i class="fas fa-link mr-1"></i> URL
          </span>
          <!-- <b-spinner small label="Small Spinner" v-if="is_loading"></b-spinner> -->
        </b-button>
        <!--Tracking2PRO-->
        <b-button variant="outline" class="ml-2" @click="trackingTwoPro()"
                  v-if="requireTracking && checkPermission('create', 'action', 'url-tracking-premium')"
                  :disabled="checkCreateTrackingTwoPro">
          <span class="mx-2 d-flex">
         <div class="mr-1 svg-background d-flex" :style="`${iconMaskURL('crown2pro')}`"
              style="width: 18px !important; height: 18px !important;"></div>
            Tracking2Pro
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <div style="padding-left: 10px;">
          <li>
            {{ $t('messageText.thaiText') }}
            <span class="text-primary font-weight-600">{{ thaiCharacters }}</span>
            {{ $t('messageText.characters_charge') }}
            <span class="text-primary font-weight-600">{{ creditPerMsg ? creditPerMsg : 1 }}</span>
            {{ $t("subscriptions.credit") }}
          </li>
          <li>
            {{ $t('messageText.engText') }}
            <span class="text-primary font-weight-600">{{ endCharacters }}</span>
            {{ $t('messageText.characters_charge') }}
            <span class="text-primary font-weight-600">{{ creditPerMsg ? creditPerMsg : 1 }}</span>
            {{ $t("subscriptions.credit") }}
          </li>
          <li>
            {{ $t('messageText.upTo') }}
            <span class="text-primary font-weight-600">{{ (creditPerMsg ? creditPerMsg : 1) * 8 }}</span>
            {{ $t("subscriptions.credit") }} {{ $t('messageText.perMessage') }}
          </li>
        </div>
      </b-col>
    </b-row>
    <!-- Default -->
    <b-row v-if="!parentComponent" class="mt-3">
      <b-col>
        <input-component :label-input="$t('sms.number_of_characters')" v-model="message.length" disabledType/>
      </b-col>
      <b-col>
        <input-component :label-input="$t('sms.use_credit')" v-model="creditUse" disabledType/>
      </b-col>
    </b-row>

    <b-row v-if="parentComponent == 'usingFile'" class="mt-3">
      <b-col lg="6" md="6" sm="12">
        <input-component :label-input="$t('sms.number_of_characters')" v-model="message.length" disabledType/>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <input-component :label-input="$t('sms.use_credit')" v-model="creditUse" disabledType/>
      </b-col>
      <b-col lg="6" md="6" sm="12">
        <input-component :label-input="$t('trackingTwoPro.labelUsingCredit')" v-model="trackingUsed" disabledType/>
      </b-col>
      <b-col lg="6" md="6" sm="12"></b-col>
    </b-row>

    <!-- CampaignBuilder -->
    <b-row v-if="parentComponent == 'campaignBuilder'" class="mt-3">
      <b-col lg="6" md="12" sm="12">
        <input-component :label-input="$t('sms.number_of_characters')" v-model="message.length" disabledType/>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <input-component :label-input="$t('messageText.total_contacts')" v-model="totalContact" disabledType/>
      </b-col>
      <b-col v-if="!hasTag" lg="6" md="12" sm="12">
        <input-component :label-input="$t('sms.use_credit')" v-model="creditUse" disabledType/>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <input-component :label-input="$t('trackingTwoPro.labelUsingCredit')" v-model="trackingUsed" disabledType/>
      </b-col>
    </b-row>
    <b-modal id="url_list_modal" :title="$t('short_link.create.title')" hide-footer no-close-on-backdrop no-close-on-esc
             size="lg">
      <template #modal-header="{ close }">
        <h5>URL</h5>
        <i class="fas fa-times hover-icon action-button" @click="closePopup()"></i>
      </template>
      <div>
        <hr style="margin-top: 0;">
        <b-row>
          <b-col>
            <input-component :label-input="$t('short_link.list.name_url')" v-model="search.title" max="30"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" md="6" sm="12">
            <select-filter-component :label-select="$t('short_link.url_tracking_type')" v-model="search.url_link_type"
                                     :options="url_link_type"/>
          </b-col>
          <b-col lg="8" md="6" sm="12">
            <b-form-group label-cols="12" :label="$t('short_link.url_link')" label-for="input-sm">
              <b-form-input v-model="search.url_link"/>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div></div>
          </b-col>
          <b-col align="end">
            <button type="button" class="btn waves-effect waves-light btn-outline ml-auto" @click="resetFilter">
              {{ $t('common.resetSearch') }}
            </button>
            <button type="button" class="btn waves-effect waves-light btn-gradient ml-2" @click="getList">
              {{ $t('common.Search') }}
              <b-spinner small v-show="is_loading"/>
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col align="end" class="mt-2 mb-1">
            <div v-if="!is_loading">
              <span v-if="!has_current_package" class="text-danger">
                {{ $t('short_link.order_package') }}
              </span>
              <span v-else-if="!url_tracking_limit" class="text-danger">
                {{ $t('short_link.url_credit_out') }}
              </span>
            </div>
          </b-col>
        </b-row>
        <hr style="margin-top: 0;">
        <b-row align-v="center" class="pb-3">
          <b-col>
            <div class="d-flex align-items-center">
              <span class="mr-3">{{ $t('common.perPage') }}</span>
              <div>
                <b-form-select v-model="search.$limit" :options="pageOptions" size="sm"/>
              </div>
            </div>
          </b-col>
          <b-col align="end">
            <b-button v-if="!has_current_package" variant="outline" @click="goToSubscriptionsPage">
              <div class="flex-center">
                <div style="width: 18px; height: 18px;" class="mr-1 svg-background"
                     :style="`${iconMaskURL('shopping-cart')}`">
                </div>
                {{ $t("short_link.buy_package") }}
              </div>
            </b-button>
            <b-button v-else
                      :disabled="!url_tracking_limit || is_loading || !checkPermission('create', 'action', 'url-trackings')"
                      @click="checkPermission('create', 'action', 'url-trackings') && createShortUrl()"
                      variant="outline">
              <i class="fas fa-plus-circle"/>
              {{ $t("short_link.add_short_url") }}
            </b-button>
          </b-col>
        </b-row>
        <b-table responsive class="mb-0" :items="items" :fields="field" :busy="isBusy" :empty-text="$t('empty')"
                 show-empty>
          <template #head()="{ label, field: { key, sortable } }">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(title)="data">
            {{ data.item.title }}
          </template>
          <template #cell(main_link)="data">
            <div style="max-width: 220px;">
              <a :href="data.item.main_link" target="_blank" class="text-info cutting-link">{{
                data.item.main_link
                }}</a>
            </div>
          </template>
          <template #cell(short_link)="data">
            <div v-if="data.item.short_link" class="d-flex">
              <span>
                {{ data.item.short_link }}
              </span>
              <div :id="'11_'" class="ml-3 copy-icon" @click="copyText(data.item.short_link)">
                <i class="fas fa-copy"/>
              </div>
            </div>
          </template>
          <template #cell(active)="data">
            <div>
              <b-button variant="primary" @click="addShortLink(data.item)">
                {{ $t("sms.select") }}
              </b-button>
            </div>
          </template>
        </b-table>
        <b-row>
          <div class="ml-auto">
            <b-pagination v-model="search.$skip" :total-rows="totalRows" :per-page="search.$limit" align="fill"
                          class="my-0" size="sm" @input="getList"/>
          </div>
        </b-row>
      </div>
    </b-modal>
    <create-short-url @success="createSuccess"/>
    <tracking-two-pro @urlTackingTwoPro="urlTackingTwoPro"/>
    <b-modal id="modal-response" ok-variant="info" hide-footer no-close-on-backdrop>
      <div class="text-center">
        <h2 class="text-green">{{ $t('short_link.add_short_url_s') }}</h2>
        <div class="font-size-18 mt-4">{{ $t('short_link.copy_link_and') }}</div>
        <div class="font-size-18 mt-4" style="color: #479B87">
          {{ $t('short_link.details.name_URL') }} : {{ response_created.title }}
        </div>
        <div class="flex-middle mt-4 mb-3">
          <div class="text-short-link" style="color: #479B87">
            {{ response_created.short_link }}
          </div>
          <div class="ml-2 copy-icon" @click="copyText(response_created.short_link)">
            <i class="fas fa-copy"/>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {smsCalculator} from "@sms2pro/sms2pro"
import subscriptionApi from "@/repository/subscriptionApi";
import InputComponent from "@/components/common/inputComponent";
import moment from "moment";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import shortLinkApi from "@/repository/shortLinkApi";
import masterData from "@/common/masterData.json";
import EmptyTable from "@/components/common/emptyTable.vue";
import CreateShortUrl from "@/views/shortLink/createShortUrl.vue";
import functions from "@/common/functions";
import authApi from "@/repository/authApi";
import inputEmojiComponent from "@/components/common/inputEmojiComponent.vue";
import trackingTwoPro from "@/views/sms/trackingTwoPro.vue";

export default {
  name: "inputMessageComponent",
  components: {
    trackingTwoPro,
    CreateShortUrl,
    InputComponent,
    SelectFilterComponent,
    EmptyTable,
    inputEmojiComponent
  },
  props: {
    requireTracking: {
      type: Boolean,
      request: false,
      default: false
    },
    className: {
      type: String,
      request: false
    },
    showCount: {
      type: Boolean,
      request: false,
      default: false
    },
    max: {
      type: String,
      request: false
    },
    type: {
      type: String,
      request: false
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    error: {
      type: String,
      request: false
    },
    requireLabel: {
      type: String,
      request: false
    },
    placeholder: {
      type: String,
      request: false
    },
    inputId: {
      type: String,
      request: false
    },
    rows: {
      type: String,
      request: false
    },
    maxRows: {
      type: String,
      request: false
    },
    labelInput: {
      type: String,
      request: false
    },
    disabledType: {
      type: Boolean,
      request: false,
      default: false
    },
    bgColor: {
      type: String,
      request: false
    },
    value: {
      type: String,
      request: false
    },
    required: {
      type: Boolean,
      request: false,
      default: false
    },
    parentComponent: {
      type: String,
      request: false
    },
    totalContact: {
      type: Number,
      request: false,
      default: 0
    },
    hasTag: {
      type: Boolean,
      request: false,
      default: false
    }
  },
  data() {
    return {
      maxTooltip: false,
      tempDomain: null,
      tempMainLink: null,
      trackingTwoProId: null,
      trackingUsed: 0,
      formatted: '',
      selected: '',
      messageCount: 0,
      charLeft: 0,
      language: "eng",
      maxChar: 0,
      msgType: "E",
      smsCount: 0,
      smsType: "plain",
      totalLength: 0,
      thaiCharacters: 70,
      endCharacters: 160,
      creditPerMsg: 1,
      search: {
        url_link_type: null,
        url_link: '',
        $skip: 1,
        $limit: 10,
        title: ""
      },
      isBusy: false,
      field: [
        {key: "title", label: "short_link.list.name_url"},
        {key: "main_link", label: "short_link.list.full_url"},
        {key: "short_link", label: "short_link.list.short_url"},
        {key: "active", label: "short_link.list.action", class: "text-center"},
      ],
      totalRows: 1,
      pageOptions: [10, 15, 50, 100],
      url_link_type: masterData.url_link_type,
      is_loading: false,
      items: [],
      url_tracking_limit: 0,
      has_current_package: false,
      response_created: {
        title: "",
        short_link: "",
      },
      message: ''
    }
  },
  computed: {
    urlTrackingError() {
      return this.value.includes('{url-tracking}') ? '' : this.$t('trackingTwoPro.err.required')
    },
    checkCreateTrackingTwoPro() {
      return !_.isNil(this.tempMainLink) || !_.isNil(this.trackingTwoProId) || !_.isNil(this.tempDomain)
    },
    perMessage() {
      return this.maxChar / this.smsCount
    },
    creditUse() {
      return (this.creditPerMsg ? this.creditPerMsg : 1) * this.smsCount
    }
  },
  watch: {
    creditUse() {
      this.$emit('credit-use', this.creditUse + " " + this.$t("subscriptions.credit"))
    },
    value: function (val) {
      if (!!val) {
        if (!_.isNil(this.trackingTwoProId) && !_.isNil(this.tempMainLink) && !_.isNil(this.tempDomain)) {
          val.includes('{url-tracking}') ? this.trackingUsed = 1 : this.trackingUsed = 0
          this.message = val.replace(/\{url-tracking\}/g, this.tempDomain)
          this.smsCalculator(val.replace(/\{url-tracking\}/g, this.tempDomain))
          const data = {
            id: this.trackingTwoProId,
            error: !val.includes('{url-tracking}')
          }
          this.$emit('trackingTwoPro', data)
        } else {
          this.trackingUsed = 0
          this.message = val
          this.smsCalculator(val)
        }
      }
    }
  },
  created() {
    this.getCurrentPackageDetail()
    this.getCustomerProfile()
    if (this.checkPermission('list', 'action', 'url-trackings')) {
      this.getList()
    }
  },
  mounted() {
    this.checkRetargetImport()
  },
  methods: {
    removeURLTracking() {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('trackingTwoPro.modal.warningDelete'),
        text: this.$t('trackingTwoPro.modal.subWarningDelete'),
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('common.confirm'),
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.tempMainLink = null
          this.trackingTwoProId = null
          this.tempDomain = null
          this.trackingUsed = 0
          const message = this.value
          this.message = message.replace(/\{url-tracking\}/g, '')
          this.updateValue(message.replace(/\{url-tracking\}/g, ''))
          const data = {
            id: this.trackingTwoProId,
            error: false
          }
          this.$emit('trackingTwoPro', data)
        }
      })
    },
    checkWidth(id) {
      const element = document.getElementById(id)
      const maxContent = document.getElementById('input-message-custom-badge')
      if (element && maxContent) {
        this.maxTooltip = maxContent.offsetWidth - element.offsetWidth <= 40
      }
    },
    urlTackingTwoPro(param) {
      this.trackingTwoProId = param.id
      this.tempMainLink = param.main_link
      this.tempDomain = `https://${param.domain}/xxxxxxx`
      this.trackingUsed = param.trackingUsed ? param.trackingUsed : 0
      const message = this.value + '{url-tracking}'
      this.updateValue(message);
    },
    checkRetargetImport() {
      let retarget = localStorage.getItem('temp_retarget') ? JSON.parse(localStorage.getItem('temp_retarget')) : null
      if (!_.isNil(retarget)) {
        this.message = this.value
        this.smsCalculator(this.value)
      }
    },
    insert(emoji) {
      const message = this.value + emoji
      this.updateValue(message);
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    createSuccess(res) {
      this.getList()
      this.response_created = res;
      this.$bvModal.show('modal-response');
    },
    createShortUrl() {
      this.$bvModal.show('create-short-url')
    },
    iconMaskURL(name) {
      return functions.iconMaskURL(name)
    },
    goToSubscriptionsPage() {
      this.$router.push("/Subscriptions/Package");
    },
    smsCalculator(msg) {
      if (msg !== null || msg !== '') {
        const sms = smsCalculator(msg)
        this.charLeft = sms.totalLength === 0 ? 0 : sms.charLeft
        this.language = sms.language
        this.maxChar = sms.totalLength === 0 ? 0 : sms.max
        this.msgType = sms.msgType
        this.smsCount = sms.totalLength === 0 ? 0 : sms.smsCount
        this.smsType = sms.smsType
        this.totalLength = sms.totalLength
        this.$emit('max-input', this.smsCount)
        if (this.smsCount > 8) this.alertMax()
      }
    },
    getList() {
      this.isBusy = true
      shortLinkApi.getList(_.cloneDeep(this.search)).then(resp => {
        this.items = resp.data
        this.totalRows = resp.total
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
        this.isBusy = false;
      })
    },
    resetFilter() {
      this.search = {
        url_link_type: null,
        url_link: '',
        $skip: 1,
        $limit: 10,
        title: ""
      }
      this.getList()
    },
    alertMax() {
      this.$swal.fire({
        html: this.$t('common.maxMessage'),
        icon: 'info',
        confirmButtonColor: '#3085d6',
      })
    },
    getCustomerProfile() {
      authApi.getCustomerProfile().then(resp => {
        this.url_tracking_limit = resp.url_tracking;
        this.has_current_package = resp.current_plan_id && (resp.is_using_trial !== true);
      }).catch(err => {
        throw err
      })
    },
    getCurrentPackageDetail() {
      subscriptionApi.getCurrentPackageDetail().then(resp => {
        if (resp?.codeSYS === '001') {
          this.creditPerMsg = resp?.price_plan?.plain_sms
        } else {
          this.creditPerMsg = 1
        }
      }).catch(err => {
      })
    },
    updateValue(e) {
      this.smsCalculator(e)
      this.$emit('input', e)
      this.$emit('output', this.perMessage)
    },
    shortLinkList() {
      this.$bvModal.show('url_list_modal')
    },
    trackingTwoPro() {
      authApi.getCustomerProfile().then((res) => {
        if (res?.url_tracking_premium && res?.url_tracking_premium > 0) {
          this.$bvModal.show('tracking-two-pro-modal')
        } else {
          this.$swal.fire({
            icon: 'warning',
            title: this.$t('trackingTwoPro.modal.emptyCredit'),
            text: this.$t('trackingTwoPro.modal.subEmptyCredit'),
            showCloseButton: false,
            showCancelButton: true,
            confirmButtonColor: '#EA5455',
            cancelButtonColor: '#7367F0',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.byPackage'),
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              const current_plan_id = localStorage.getItem('current_plan_id')
              this.$router.push(`/Subscriptions/Package?current=${current_plan_id}`)
            }
          })
        }
      }).catch(() => {

      })
    },
    closePopup() {
      this.$bvModal.hide('url_list_modal')
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.$bvToast.toast(`Copy Successful`, {
        title: 'Notice!',
        // toaster: 'b-toaster-bottom-right',
        variant: 'success',
        autoHideDelay: 2000
      })
    },
    addShortLink(data) {
      this.updateValue(`${this.value ? this.value + " " : ""}${data.short_link} `)
      this.$bvModal.hide('url_list_modal')
      this.$bvToast.toast(`Add short link Successful`, {
        title: 'Notice!',
        variant: 'success',
        autoHideDelay: 2000
      })
    }
  },
}
</script>

<style scoped>
.custom-badge {
  padding: 6px 12px;
  width: 100%;
  text-align: left;
  background-color: #f7f7f7 !important;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-badge--detail {
  color: #586A8F !important;
  height: 21px;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-area-text-box {
  background: #FFFFFF;
  border: 1px solid #D5DFE9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #586A8F !important;
}

.label-for-input {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px;
}

.form-control {
  font-family: "Prompt";
  font-size: 16px;
}

.input-disable-gray {
  background: #F8F8F8 !important;
  border: 0px;
}

.input-disable-blue {
  background: #EFF6FF !important;
  border: 1px solid #EFF6FF;
}

.tooltip.show {
  text-align: left;
}

.cutting-link {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-short-link {
  font-size: 18px;
  border-radius: 20px;
  border: 1px solid #E9E9E9;
  background: #F6F6F6;
  padding: 6px 24px;
  margin-right: 12px;
}
</style>