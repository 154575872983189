<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4" v-if="checkPermission('list', 'action', 'blacklists')">
        <b-row>
          <b-col lg="4" md="3" sm="4">
            <b-form-group
                label-cols="12"
                :label="$t('contacts.blacklist.number')"
                label-for="input-sm"
            >
              <b-form-input v-model="form.phone_number"/>
            </b-form-group>
          </b-col>
          <b-col style="margin-top: 35px;">
            <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findBlacklist">
              {{ $t('common.Search') }}
            </button>
            <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
              {{ $t('common.resetSearch') }}
            </button>
          </b-col>
        </b-row>
        <hr/>
        <b-row align-v="center" class="pb-3">
          <b-col>
            <div class="d-flex align-items-center">
              <span class="mr-3">{{ $t('common.perPage') }}</span>
              <div>
                <b-form-select
                    v-model="form.$limit"
                    :options="pageOptions"
                    size="sm"
                    @change="findBlacklist"
                />
              </div>
              <div class="ml-3">
                <total-list-component :total="totalAll"/>
              </div>
            </div>
          </b-col>
          <b-col cols="4" align="end">
            <b-button variant="primary" v-b-modal.modal-create-blacklist
                      :disabled="!checkPermission('create', 'action', 'blacklists')">
              <i class="fas fa-plus-circle"></i>
              {{ $t('button.addNew') }}
            </b-button>
          </b-col>
        </b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(actions)="data">
            <i v-if="!data.item.from_system && checkPermission('delete', 'action', 'blacklists')" style="color: #DF3F1E"
               @click="deleteBlacklist(data.item._id)" class="action-button icon-trash "/>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <div class="ml-auto">
      <b-pagination
          v-model="form.$skip"
          :total-rows="totalRows"
          :per-page="form.$limit"
          align="fill"
          class="my-0"
          size="sm"
          @input="findBlacklist"
      />
    </div>
    <create-blacklist @output="findBlacklist"/>
  </b-row>
</template>

<script>
import CreateBlacklist from "@/views/contacts/blackList/create";
import blacklistApi from "@/repository/blacklistApi";
import _ from "lodash";
import alert from "@/common/alert";
import functions from "@/common/functions";
import {validatePhoneNumber} from "@sms2pro/sms2pro";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "blackList",
  data: () => ({
    totalAll: 0,
    form: {
      phone_number: '',
      $skip: 1,
      $limit: 10,
    },
    fields: [
      {
        key: "phone_number",
        label: "contacts.blacklist.number",
      },
      {
        key: "reason",
        label: "contacts.blacklist.reason",
      },
      {
        key: 'createdAt',
        label: 'contacts.blacklist.createdAt',
      },
      {
        key: "actions",
        label: "contacts.blacklist.actions",
      },

    ],
    isBusy: false,
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  components: {TotalListComponent, EmptyTable, CreateBlacklist},
  mounted() {
    this.findBlacklist()
  },
  methods: {
    validatePhoneNumber(data) {
      const phone = data ? validatePhoneNumber(data) : ''
      return phone ? phone : data
    },
    findBlacklist() {
      this.isBusy = !this.isBusy
      this.form.phone_number = this.validatePhoneNumber(this.form.phone_number)
      blacklistApi.findBlacklistAll(_.cloneDeep(this.form)).then((response) => {
        this.totalAll = response.total
        this.totalRows = response.total
        this.items = response.data
      }).catch((err) => {
        alert.findFailed(this, err)
      }).finally(() => {
        this.isBusy = !this.isBusy
        this.$emit('output', true)
      })
    },
    changeDateFormat(date) {
      return date ? functions.changeDateFormat(date) : '-'
    },
    deleteBlacklist(id) {
      this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('common.delete.topic'),
            text: this.$t('common.delete.detail'),
            showCancelButton: true,
            confirmButtonColor: '#EA5455',
            cancelButtonColor: '#7367F0',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.yes'),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$emit('is-loading', true)
              blacklistApi.deleteBlacklist(id).then((response) => {
                if (response?.codeSYS === '001') {
                  alert.deleteSuccess(this)
                  this.findBlacklist()
                } else {
                  alert.deleteFailed(this, response.message)
                }
              }).catch((err) => {
                alert.deleteFailed(this, err)
              }).finally(() => {
                this.$emit('is-loading', false)
              })
            }
          })
    },
    resetSearch() {
      this.form = {
        number: '',
        $skip: 1,
        $limit: 10,
      }
      this.findBlacklist()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>
