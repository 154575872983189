<template>
  <b-row>
    <b-col sm="12" lg="7" md="7">
      <div>
        <input-component :label-input="$t('campaigns.campaignFrom.name')" v-model="$v.form.name.$model"
                         :state="validateState($v.form.name)" required/>
        <select-component :label-select="$t('setting.senderID.form.senderID')"
                          :textRightRouter="checkPermission('list', 'action', 'sender-names')? 'setting.requestNew' : null"
                          :pathRightRouter="checkPermission('create', 'action', 'sender-names') ? '/Setting/SenderID/Create-SenderName': null"
                          v-model="$v.form.sender_name.$model" :state="validateState($v.form.sender_name)"
                          required
                          :options="originatorList"
                          text-field="text"
        />
        <select-all-component
            :label-select="$t('campaigns.campaignFrom.contactGroup') + ' (' + $t('campaigns.campaignFrom.recipient_number') + ')'"
            :textRightRouter="checkPermission('list', 'action', 'contact-groups') ? 'contacts.create.createContactGroup' : null"
            :pathRightRouter="checkPermission('create', 'action', 'contact-groups')? '/Contacts#modal-create-contact-group' : null"
            v-model="form.contact_group_id"
            required
            :options="contactGroupList"
            text-field="text"/>

        <div class="mb-2">
          <label class="pt-2 pl-2">{{ $t('campaigns.campaignFrom.scheduleCampaign.subtitle') }}</label>
          <div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="radio-group-2"
                  v-model="sendNow"
                  :aria-describedby="ariaDescribedby"
                  name="radio-sub-component"
              >
                <b-form-radio v-for="(item, index) in options_send_type" :value="item.value"
                              :key="index">{{ $t(item.text) }}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div v-if="!sendNow">
            <hr/>
            <b-row>
              <b-col lg="6" sm="12">
                <input-component :label-input="$t('campaigns.campaignFrom.scheduleCampaign.date')"
                                 v-model="form.schedule_date" type="date" :min-date="minDate"/>
              </b-col>
              <b-col lg="6" sm="12">
                <input-component :label-input="$t('campaigns.campaignFrom.scheduleCampaign.time')"
                                 v-model="form.schedule_time" type="time"/>
              </b-col>
            </b-row>
            <label v-if="sendBeforeNow" class="ml-2 text-danger"> * {{
                $t('campaigns.campaignFrom.selectedAfterNow')
              }}</label>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between mb-0">
            <label class="text-primary">{{ $t('campaigns.campaignFrom.manualInput') }}</label>
            <label>{{ $t('campaigns.campaignFrom.totalRecipients') }} : <span class="text-blue">{{
                manualCount
              }}</span></label>
          </div>
          <input-text-area-component
              v-model="form.recipients"
              @focusout="replaceManualInput(form.recipients)"
              rows="5"
              :require-label="telInvalidString"
              max-rows="5"
          />
          <div class="text-danger font-12" v-if="manualCount > 1000">
            {{ $t('campaigns.campaignFrom.limitUpload') }}
          </div>
          <div class="mb-3">
            <b-button-group class="m-2">
              <b-button v-for="(item, index) in options"
                        :key="index"
                        @click="delimiterType(item.value)"
                        :variant="item.value === form.delimiter ? 'primary' : 'outline-primary'">
                {{ item.value }} {{ $t(item.text) }}
              </b-button>
            </b-button-group>
          </div>

        </div>
        <p class="mt-2 pt-3 pb-1 pr-2" style="background-color: #C8EBFC52; border-radius: 8px"
           v-html="$t('campaigns.campaignFrom.note')"></p>
        <b-row>
          <b-col>
            <select-component :label-select="$t('campaigns.campaignFrom.smsTemplate')"
                              v-model="smsTemplate"
                              :options="smsTemplateList"
                              @input="changeDataTemplate"
                              text-field="text"/>
          </b-col>
          <b-col>
            <select-component :label-select="$t('campaigns.campaignFrom.availableTag')"
                              v-model="availableTag"
                              :options="availableTagList"
                              @input="changeDataAvailable"
                              text-field="text"/>
          </b-col>
        </b-row>
        <input-message-component @output="messageMax"
                                 @trackingTwoPro="trackingTwoPro"
                                 require-tracking
                                 @max-input="maxInput"
                                 :label-input="$t('campaigns.campaignFrom.message')"
                                 rows="5"
                                 v-model="$v.form.message.$model"
                                 :state="validateState($v.form.message)"
                                 parentComponent="campaignBuilder"
                                 :totalContact="totalActiveContactSelected"
                                 :hasTag="messagingHasTag"
                                 required/>
        <b-row align-h="end" class="mr-1 mt-3">
          <b-button variant="warning"
                    :disabled="form.message === '' || !messages || !checkPermission('create', 'action', 'campaign-builder')"
                    @click="checkSpamWordContent()">
            <img src="@/assets/images/spell-check.png" alt="spell-check" width="20"/>
            {{ $t('button.checkSpamWord') }}
            <b-spinner small label="Small Spinner" v-if="is_loading_content_filling"/>
          </b-button>
          <b-button variant="primary" class="ml-2"
                    :disabled="$v.form.$invalid || is_loading || manualCount > 1000 || requireContact || sendBeforeNow || rate_limit_send !== 0 ||trackingErr || form.message === ''|| !changeContent || !is_check_content"
                    @click="sendMassageCampaign()">
            <div v-if="rate_limit_send !== 0">
              Resend in {{ rate_limit_send }} sec.
            </div>
            <div v-else>
              <i class="fas fa-paper-plane"></i> {{ $t('button.send') }}
              <b-spinner small label="Small Spinner" v-if="is_loading"/>
            </div>
          </b-button>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <div class="d-flex">
              <div>
                {{ $t('messageText.note') }}
              </div>
              <div align="left" style="padding-left: 10px;">
                {{ $t('messageText.note1') }} <br>
                {{ $t('messageText.note2') }} <br>
                <span class="text-danger">{{ $t('messageText.note3') }}</span> <br>
                <span class="text-danger">{{ $t('campaigns.campaignFrom.remark') }}</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col sm="12" lg="5" md="5">
      <preview :sender-name="form.sender_name" :message="form.message" :per-message="perMessage"/>
    </b-col>
    <div v-if="userInfo">
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="userInfo"/>
    </div>
  </b-row>
</template>

<script>
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import Preview from "@/views/sms/preview";
import InputMessageComponent from "@/components/common/inputMessageComponent";
import smsApi from "@/repository/smsApi";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import alert from "@/common/alert";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import moment from "moment";
import functions from "@/common/functions";
import SelectAllComponent from "@/components/common/selectAllComponent";
import Input from "@/views/smsAPI/commonAPI/input.vue";
import VerifyModal from "@/views/account/verify/index.vue";

export default {
  name: "campaignBuilder",
  components: {
    VerifyModal,
    Input,
    SelectAllComponent,
    InputTextAreaComponent, InputMessageComponent, Preview, SelectComponent, InputComponent
  },
  mixins: [validationMixin],
  data() {
    return {
      trackingErr: false,
      trackingTwoProId: null,
      options: [
        {value: ",", text: 'symbol.comma'},
        {value: ";", text: 'symbol.semicolon'},
        {value: "|", text: 'symbol.bar'},
      ],
      advanced: false,
      sendNow: true,
      perMessage: 160,
      smsTemplate: null,
      availableTag: null,
      messages: true,
      form: {
        name: '',
        sender_name: null,
        contact_group_id: null,
        recipients: '',
        message: '',
        delimiter: ',',
        schedule: false,
        schedule_date: '',
        schedule_time: '',
      },
      contactGroupList: [],
      manualCount: 0,
      is_loading: false,
      minDate: moment(new Date()).format('YYYY-MM-DD'),
      smsTemplateList: [],
      availableTagList: [],
      senderPreview: '',
      oldMessage: '',
      is_loading_content_filling: false,
      is_check_content: false,
      rate_limit_send: 0,
      options_send_type: [
        {text: 'campaigns.campaignFrom.send_type_now', value: true},
        {text: 'campaigns.campaignFrom.scheduleCampaign.text', value: false},
      ],
      is_index_send_type: 0
    }
  },
  validations: {
    form: {
      name: {required},
      sender_name: {required},
      message: {required},
    }
  },
  mounted() {
    this.getOriginatorList()
    this.getContactGroupList()
    this.getSMSTemplateList()
    this.getAvailableTagList()
  },
  computed: {
    changeContent() {
      return this.is_check_content && (this.oldMessage === this.form.message)
    },
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    originatorList() {
      return this.$store.getters['sender/getSenderList']
    },
    requireContact() {
      return this.form.contact_group_id === null && this.form.recipients === ''
    },
    sendBeforeNow() {
      if (!this.sendNow) {
        if (this.form.schedule_date === '' || this.form.schedule_time === '') {
          return true
        } else {
          return new Date().getTime() > new Date(this.form.schedule_date + 'T' + this.form.schedule_time).getTime()
        }
      } else {
        return false
      }
    },
    telInvalid() {
      let spliter = ''
      if (this.form.delimiter === ',') spliter = ','
      else if (this.form.delimiter === ';') spliter = ';'
      else if (this.form.delimiter === '|') spliter = '|'
      let telNum = _.split(this.form.recipients, spliter)
      let isInvalid = false
      telNum.forEach((number) => {
        let n = _.trim(number)
        if (!this.checkNumber(n)) {
          isInvalid = true
        }
      })
      return isInvalid
    },
    telInvalidString() {
      const reg = /^[0-9]*((-|\s)*[0-9])*$/g
      let spliter = ''
      if (this.form.delimiter === ',') spliter = ','
      else if (this.form.delimiter === ';') spliter = ';'
      else if (this.form.delimiter === '|') spliter = '|'

      let telNum = _.split(this.form.recipients, spliter)
      let alertString = ''
      telNum.forEach((number) => {
        let n = _.trim(number)
        if (n.length !== 0) {
          if (!n.match(reg)) alertString = this.$t('common.telNumOnly')
          if (n.charAt(0) !== '0' && n.charAt(0) !== '6') {
            alertString = this.$t('common.telStart')
          } else if ((n.charAt(1) !== '6' && n.charAt(1) !== '8' && n.charAt(1) !== '9')) {
            alertString = this.$t('common.telStart')
          } else if (n.length < 10) {
            alertString = this.$t('common.telLength')
          } else if (n.length === 11 && (n.charAt(0) !== '6' && n.charAt(1) !== '6')) {
            alertString = this.$t('common.telStart66')
          } else if ((n.charAt(0) === '6' && n.charAt(1) === '6') && n.charAt(2) !== '6' && n.charAt(2) !== '8' && n.charAt(2) !== '9') {
            alertString = this.$t('common.telFormat')
          }
        }
      })
      return alertString
    },
    totalActiveContactSelected() {
      let total = 0
      if (this.form.contact_group_id !== null) {
        total = this.contactGroupList.filter((resp) => {
          return this.form.contact_group_id === resp?.value
        }).map(resp => resp?.total_active_contact).join(',')
      }
      const activeNumber = Number(total) + this.manualCount
      return activeNumber
    },
    messagingHasTag() {
      let hasTag = false
      this.availableTagList.forEach((item) => {
        if (this.form.message.includes(item.value)) {
          hasTag = true
        }
      })
      return hasTag
    },
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    },
  },
  methods: {
    trackingTwoPro(param) {
      this.trackingTwoProId = param.id
      this.trackingErr = param.error
    },
    maxInput(e) {
      this.messages = e < 9
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    messageMax(e) {
      this.perMessage = e
    },
    delimiterType(e) {
      this.form.delimiter = e
    },
    selectSendType(e, index) {
      this.is_index_send_type = _.cloneDeep(index)
    },
    getOriginatorList() {
      const data = {
        type: 'mkt',
        status: 'active',
        $limit: 50000,
        is_only_own: 0
      }
      smsApi.getOriginator(data).then(resp => {
        this.$store.dispatch('sender/setSenderList', resp)
      }).catch(err => {
        throw err
      })
    },
    changeSender(data) {
      this.senderPreview = this.originatorList.filter((resp) => {
        return data === resp?.value
      }).map(resp => resp?.text).join(',')
    },
    getContactGroupList() {
      smsApi.getContactGroupDropDownList().then(resp => {
        this.contactGroupList = resp?.data?.filter(filter => filter?.total_active_contact !== 0).map(item => {
          return {
            value: item?._id,
            text: item?.name + " " + `(${item?.total_active_contact?.toLocaleString()} ${this.$t('campaigns.campaignFrom.totalContact')})`,
            total_active_contact: item?.total_active_contact
          }
        })
      }).catch(err => {
        throw err
      })
    },
    replaceManualInput(msg) {
      let regExp = /\d+/g;
      if (regExp.test(msg)) {
        let trim = msg.trim()
        let nameArr = trim.match(regExp);
        let findDuplicate = nameArr.filter(function (value, index, array) {
          return array.indexOf(value) === index && value !== '';
        })
        let delimiter = ''
        if (this.form.delimiter === ',') delimiter = ','
        else if (this.form.delimiter === ';') delimiter = ';'
        else if (this.form.delimiter === '|') delimiter = '|'
        this.manualCount = findDuplicate?.length
        this.form.recipients = findDuplicate.join(delimiter)
      } else {
        this.form.recipients = ''
        this.manualCount = 0
      }
    },
    getSMSTemplateList() {
      smsApi.getSmsTemplateList().then(resp => {
        this.smsTemplateList = resp?.data?.map(item => {
          return {
            text: item?.name,
            value: item?.message
          }
        })
      }).catch(err => {
        throw err
      })
    },
    getAvailableTagList() {
      smsApi.getAvailableTagList().then(resp => {
        this.availableTagList = resp?.data?.map((item) => {
          return {
            text: item?.name,
            value: `{${item?.value}}`
          }
        })
      }).catch(err => {
        throw err
      })
    },
    sendMassageCampaign() {
      this.is_loading = true
      if (this.userInfo?.identity_verify_status === 'verified' || this.userInfo?.is_using_trial || this.userInfo?.is_using_default) {
        this.$swal.fire({
          icon: 'warning',
          title: this.$t('common.confirmSMS.topic'),
          text: this.$t('common.confirmSMS.detail'),
          showCancelButton: true,
          confirmButtonColor: '#EA5455',
          cancelButtonColor: '#7367F0',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.send'),
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.schedule = !this.sendNow
            if (!_.isNil(this.trackingTwoProId)) {
              this.form['use_tag_url'] = true
              this.form['full_link_id'] = this.trackingTwoProId
            }
            smsApi.sendMassageCampaign(this.form).then(response => {
              const resp = response?.data
              let rate = Number(response.headers['ratelimit-reset'])
              if (resp?.codeSYS === '001') {
                let timer = rate;
                let interval = setInterval(() => {
                  if (timer === 0) {
                    clearInterval(interval)
                    this.rate_limit_send = 0
                  } else {
                    this.rate_limit_send = timer--
                  }
                }, 1000)
                this.$emit('output', 'success');
                alert.sendSuccess(this)
                this.clearData()
                this.$router.push('Reports/Campaigns').catch(() => {
                })
              } else {
                let timer = rate;
                let interval = setInterval(() => {
                  if (timer === 0) {
                    clearInterval(interval)
                    this.rate_limit_send = 0
                  } else {
                    this.rate_limit_send = timer--
                  }
                }, 1000)
                alert.sendFailed(this, resp?.message)
              }
            }).catch((err) => {
              let timer = Number(err.response.headers['ratelimit-reset']);
              let interval = setInterval(() => {
                if (timer === 0) {
                  clearInterval(interval)
                  this.rate_limit_send = 0
                } else {
                  this.rate_limit_send = timer--
                }
              }, 1000)
              if (err?.response?.status !== 429 || err?.response?.status !== 401) {
                let errorCode = err?.response?.status
                if (typeof errorCode === "string") {
                  // Chang to Number
                  errorCode = Number(errorCode)
                  // Check if errorCode is NaN
                  if (isNaN(errorCode)) {
                    errorCode = err?.response?.status
                  }
                }
                if (err?.response?.data?.errors?.code === "420") {
                  const current_plan_id = localStorage.getItem('current_plan_id')
                  alert.creditIsInsufficient(this, err?.response?.data?.message, `/Subscriptions/Package?current=${current_plan_id}`)
                } else if (err?.response?.status > 500) {
                  let current_date = new Date()
                  const date_campaign = {
                    time1: moment(new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate())).format('YYYY-MM-DD') + 'T00:00:00',
                    time2: moment(new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate())).format('YYYY-MM-DD') + 'T23:59:59',
                  }
                  const queryString = new URLSearchParams(functions.removeEmptyKey(date_campaign))
                  const str = queryString.toString()
                  this.$router.push(`/Reports/Campaigns?${str}`)
                } else {
                  alert.sendFailed(this, err?.response?.data?.message)
                }
              }

            }).finally(() => {
              this.is_loading = false
            })
          } else {
            this.is_loading = false
          }
        })
      }else if (this.userInfo.identity_verify_status === 'waiting-approve') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
          title: this.$t('common.not_been_approve'),
          text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.check'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      }else if (this.userInfo.identity_verify_status === 'rejected') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_failed.svg'),
          title: this.$t('common.not_been_success_auth'),
          text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      } else {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify.svg'),
          title: this.$t('common.has_not_been_auth'),
          text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$bvModal.show('modal-verify-account')
          }
        })
      }
      this.is_loading = false

    },
    checkSpamWordContent() {
      this.oldMessage = _.cloneDeep(this.form.message)
      this.is_loading_content_filling = true
      smsApi.checkSpamWordContent(this.form.message).then(resp => {
        if (resp?.success) {
          this.is_check_content = true
          alert.checkSpamWordSuccess(this)
        } else {
          this.is_check_content = false
          const spam = this.$t('common.spamWord') + '<br>' + resp?.spams?.join(',')
          alert.checkSpamWordFailed(this, spam)
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.checkSpamWordFailed(this, err.response.data.message)
        }
      }).finally(() => {
        this.is_loading_content_filling = false
      })
    },
    changeDataAvailable(data) {
      this.form.message = _.cloneDeep(this.form.message) + data
    },
    changeDataTemplate(data) {
      if (!_.isNull(data)) {
        this.form.message = _.cloneDeep(this.form.message) + data
      }

    },
    clearData() {
      this.form = {
        name: '',
        sender_name: null,
        contact_group_id: null,
        recipients: '',
        message: '',
        delimiter: 'comma',
        schedule_date: '',
        schedule_time: ''
      }
      this.$v.form.$reset()
      this.manualCount = 0
      this.advanced = false
      this.sendNow = true
      this.perMessage = 160
      this.smsTemplate = null
      this.availableTag = null
    },
    updateVerifyInfoSuccess () {
      this.$router.push('/Account').catch(() => {})
    }
  },
  watch: {
    'form.delimiter'(val) {
      if (val) {
        this.replaceManualInput(this.form.recipients)
      }
    }
  }
}
</script>

<style scoped>
/* .send-type {
  background: #ffffff;
  width: 45%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ffffff;
}

.send-type.custom-control.custom-control-inline.custom-radio {
  padding: .5rem 2rem .5rem 2.5rem;
  cursor: pointer;
}

.send-type::v-deep .custom-control-label {
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.send-type::v-deep .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.send-type:hover {
  border: 1px solid var(--primary-color);
} */
</style>