<template>
  <div role="group">
    <div class="form-group mb-0">
      <b-row>
        <b-col class="col-auto" style="padding-right: 5px !important">
          <label class="label-select text-secondary" v-if="labelSelect"
          >{{ labelSelect }}
          </label>
        </b-col>
        <b-col
            v-if="required"
            align="left"
            class="col-2"
            style="padding-left: 5px !important"
        >
          <strong v-if="requireLabel || required" style="color: red">*</strong>
        </b-col>
        <b-col v-if="textRightRouter && pathRightRouter" class="col-auto ml-auto mb-2" align="right"
               style="padding-left: 5px !important">
          <span @click="$router.push(pathRightRouter)" class="text-primary font-weight-600 text-router">
            {{ $t(textRightRouter) }}
          </span>
        </b-col>
      </b-row>
      <b-form-select
          :value="value"
          :class="`input-select ${error} ${classInput}`"
          :style="`${componentStyle}`"
          :options="options"
          :v-model="value"
          @input="updateValue"
          @change="changeValue"
          :text-field="field"
          :state="state"
          :size="size"
      >
        <template #first>
          <b-form-select-option :value="null">
            {{ placeholder ? "placeholder" : $t("common.pleaseSelectOption") }}
          </b-form-select-option>
        </template>
      </b-form-select>
    </div>
    <div>
      <label style="color: #fc6c6b; margin-bottom: 0px">{{
        requireLabel
        }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectAllComponent",
  props: {
    selectId: String,
    placeholder: String,
    value: {
      type: [String, Number],
      required: false,
    },
    state: {
      type: [String, Boolean],
      required: false,
      default: null
    },
    requireLabel: {
      type: String,
      request: false
    },
    error: {
      type: String,
      request: false
    },
    componentStyle: String,
    labelSelect: String,
    textField: {
      type: String,
      request: false
    },
    options: Array,
    required: {
      type: Boolean,
      request: false,
      default: false
    },
    radius: String,
    classInput: String,
    size: {
      type: String,
      request: false,
      default: ''
    },
    textRightRouter: {
      type: String,
      request: false,
      default: ''
    },
    pathRightRouter: {
      type: String,
      request: false,
      default: ''
    },
  },
  computed: {
    field() {
      return this.textField ? this.textField : (this.$i18n.locale ? this.$i18n.locale : 'en')
    }
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event)
    },
    changeValue(event) {
      this.$emit('change', event)
    }
  },
  data() {
    return {
      selectedValue: null,
    }
  },

}
</script>

<style scoped>
.input-select {
  /*// background: #FFFFFF;*/
  border: 1px solid #d5dfe9 !important;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff url("../../assets/images/icon/arrow-down.svg") right 0.75rem center/15px 7px no-repeat;
  background-size: 20px 20px;
  height: calc(1.5em + 0.75rem + 2px);
  line-height: normal;
}

.custom-select {
  font-family: "Prompt";
  font-size: 0.875rem;
}

.label-select {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 0px;
}

.error {
  border-radius: 5px;
}

.text-router {
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
</style>
