import service from './index'
import functionsCommon from "@/common/functions";

export default {
    getSpamWordAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`/brand-spam/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },

}