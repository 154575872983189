<template>
  <b-row>
    <b-col sm="12" lg="7" md="7">
      <div>
        <div>
          <input-component :label-input="$t('campaigns.quickSendFrom.receiver') + ' (' + $t('campaigns.campaignFrom.recipient_number') + ')' "
                           v-model="$v.form.tel.$model"
                           required
                           placeholder="0999999999"
                           :require-label="validateState($v.form.tel) !== null && !$v.form.tel.required   ? $t('common.requiredField') : (!telState ? telInvalid : '')"
                           :state="validateState($v.form.tel) && telState" @keypress="chkNumber" max="11"/>
        </div>
        <div>
          <select-component :label-select="$t('setting.senderID.form.senderID')" v-model="$v.form.sender.$model"
                            :textRightRouter="checkPermission('list', 'action', 'sender-names') ? 'setting.requestNew': null"
                            :pathRightRouter="checkPermission('create', 'action', 'sender-names') ? '/Setting/SenderID/Create-SenderName': null"
                            required
                            :options="originatorList"
                            text-field="text"
                            @input="changeSender"
                            :state="validateState($v.form.sender)"/>
        </div>
        <div>
          <input-message-component
              @output="messageMax"
              @max-input="maxInput"
              :label-input="$t('campaigns.quickSendFrom.message')" rows="5"
              :state="validateState('quickSendFrom')"
              :disabled-type="is_using_trial"
              v-model="$v.form.message.$model"
              required
              @credit-use="creditUse = $event"
          />
        </div>
        <b-row align-h="end" class="mr-1">
          <b-button variant="warning" :disabled="form.message === ''|| !messages" @click="checkSpamWordContent()">
            <img src="@/assets/images/spell-check.png" alt="spell-check" width="20"/>
            {{ $t('button.checkSpamWord') }}
            <b-spinner small label="Small Spinner" v-if="is_loading_content_filling"></b-spinner>
          </b-button>

          <b-button class="ml-2" variant="primary" @click="sendSms()"
                    v-if="checkPermission('create', 'action', 'quick-send')"
                    :disabled="$v.form.$invalid || is_loading || !telState  || form.message === ''|| !changeContent || !is_check_content"><i
              class="fas fa-paper-plane"></i> {{ $t('button.send') }}
            <b-spinner small label="Small Spinner" v-if="is_loading"></b-spinner>
          </b-button>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            <div class="d-flex">
              <div>
                {{ $t('messageText.note') }}
              </div>
              <div align="left" style="padding-left: 10px;">
                {{ $t('messageText.note1') }} <br>
                {{ $t('messageText.note2') }} <br>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col sm="12" lg="5" md="5">
      <preview :sender-name="senderPreview" :message="form.message" :per-message="perMessage"/>
    </b-col>
    <div v-if="userInfo">
      <VerifyModal @update="updateVerifyInfoSuccess" :customer-profile="userInfo"/>
    </div>
  </b-row>
</template>
<script>
import InputComponent from "@/components/common/inputComponent";
import Preview from "@/views/sms/preview";
import SelectComponent from "@/components/common/selectComponent";
import InputMessageComponent from "@/components/common/inputMessageComponent";
import smsApi from "@/repository/smsApi";
import alert from "@/common/alert";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import functionsCommon from "@/common/functions";
import error from "@/views/authentication/Error";
import subscriptionApi from "@/repository/subscriptionApi";
import {smsCalculator} from "@sms2pro/sms2pro"
import functions from "@/common/functions";
import VerifyModal from "@/views/account/verify/index.vue";

export default {
  name: "quickSend",
  components: {VerifyModal, InputMessageComponent, SelectComponent, Preview, InputComponent},
  mixins: [validationMixin],
  data() {
    return {
      test: '',
      isValidUrl: false,
      is_using_trial: localStorage.getItem("is_using_trial") === 'true',
      form: {
        sender: null,
        tel: '',
        message: '',
      },
      messages: true,
      perMessage: 0,
      is_loading: false,
      is_loading_content_filling: false,
      is_check_content: false,
      senderPreview: '',
      oldMessage: '',
      creditUse: "",
    }
  },
  validations: {
    form: {
      sender: {required},
      tel: {required},
      message: {required},
    }
  },
  mounted() {
    this.getOriginatorList()
    if (this.$route.query.recipient) {
      this.form.tel = this.$route.query.recipient
    }

  },
  created() {
    this.getCurrentPackageDetail()
  },
  computed: {
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    changeContent() {
      return this.is_check_content && (this.oldMessage === this.form.message)
    },
    originatorList() {
      return this.$store.getters['sender/getSenderList']
    },
    telState() {
      return functionsCommon.telState(this.form.tel)
    },
    telInvalid() {
      const data = functionsCommon.telInvalid(this.form.tel)
      return this.$t(data)
    },
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  methods: {
    getCurrentPackageDetail() {
      if (this.is_using_trial) {
        this.form.message = sessionStorage.getItem('free_trial_message')
      }
    },
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    messageMax(e) {
      this.perMessage = e
    },
    maxInput(e) {
      this.messages = e < 9
    },
    sendSms() {
      this.is_loading = true
      const text = this.creditUse ?
          `${this.$t('common.confirmSMS.confirm_credit_used')} : ${this.creditUse}` :
          this.$t('common.confirmSMS.detail');
      if (this.userInfo?.identity_verify_status === 'verified' || this.userInfo?.is_using_trial || this.userInfo?.is_using_default) {
        this.$swal.fire({
          icon: 'warning',
          title: this.$t('common.confirmSMS.topic'),
          text: text,
          showCancelButton: true,
          confirmButtonColor: '#EA5455',
          cancelButtonColor: '#7367F0',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.send'),
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            smsApi.quickSendSMS(this.form, this.senderPreview).then(resp => {
              if (resp?.success) {
                this.$emit('output', 'success');
                alert.sendSuccess(this)
                this.clearData()
                this.$router.push('Reports/Message').catch(() => {
                })
              } else {
                alert.sendFailed(this, resp?.message)
              }
            }).catch((err) => {
              if (err?.response?.status !== 401) {
                if (err?.response?.data?.errors?.code === "420") {
                  const current_plan_id = localStorage.getItem('current_plan_id')
                  alert.creditIsInsufficient(this, err?.response?.data?.message, `/Subscriptions/Package?current=${current_plan_id}`)
                } else {
                  alert.sendFailed(this, err?.response?.data?.message)
                }
              }
            }).finally(() => {
              this.is_loading = false
            })
          } else {
            this.is_loading = false
          }
        })
      }else if (this.userInfo.identity_verify_status === 'waiting-approve') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
          title: this.$t('common.not_been_approve'),
          text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.check'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      }else if (this.userInfo.identity_verify_status === 'rejected') {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify_failed.svg'),
          title: this.$t('common.not_been_success_auth'),
          text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push('/Account').catch(() => {
            })
          }
        })
      } else {
        this.$swal.fire({
          imageUrl:require('@/assets/images/icon/verify.svg'),
          title: this.$t('common.has_not_been_auth'),
          text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
          showCancelButton: true,
          confirmButtonColor: '#19CBAC',
          cancelButtonColor: '#5B656E',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.verify'),
          allowOutsideClick: false,
          width:'40em',
          padding:'1.25rem'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$bvModal.show('modal-verify-account')
          }
        })
      }
      this.is_loading = false

    },
    chkNumber(e) {
      return functionsCommon.checkNumber(e)
    },
    getOriginatorList() {
      const data = {
        type: 'mkt',
        status: 'active',
        $limit: 50000,
        is_only_own: 0
      }
      smsApi.getOriginator(data).then(resp => {
        this.$store.dispatch('sender/setSenderList', resp)
      }).catch(err => {
        throw err
      })
    },
    changeSender(data) {
      this.senderPreview = data
    },
    clearData() {
      this.form = {
        sender: null,
        tel: '',
        message: '',
      }
      this.perMessage = 0
      this.$v.form.$reset()
    },
    checkSpamWordContent() {
      this.oldMessage = _.cloneDeep(this.form.message)
      this.is_loading_content_filling = true
      smsApi.checkSpamWordContent(this.form.message).then(resp => {
        if (resp?.success) {
          this.is_check_content = true
          alert.checkSpamWordSuccess(this)
        } else {
          this.is_check_content = false
          const spam = this.$t('common.spamWord') + '<br>' + resp?.spams?.join(',')
          alert.checkSpamWordFailed(this, spam)
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.checkSpamWordFailed(this, err.response.data.message)
        }
      }).finally(() => {
        this.is_loading_content_filling = false
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    updateVerifyInfoSuccess () {
      this.$router.push('/Account').catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>