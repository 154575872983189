<template>
  <div>
    <b-modal id="create-short-url" :title="$t('short_link.create.title')" hide-footer
             no-close-on-backdrop no-close-on-esc>
      <template #modal-header="{ close }">
        <h5>{{ $t('short_link.create.title') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="closePopup()"></i>
      </template>
      <div>
        <input-component :label-input="$t('short_link.list.name_url')"
                         v-model="$v.form.name_url.$model"
                         required
                         max="30"
                         :className="message_name_error ? 'error' : ''"
                         :state="validateState('name_url')"
                         :require-label="validateState('name_url') !== null && !$v.form.name_url.required  ? $t('common.requiredField') : ''"
                         :placeholder="` ${$t('subscriptions.customPackage.PleaseFill')}`"/>
      </div>
      <div class="flex-center" style="margin-top: -1rem;">
        <div class="text-danger">{{ message_name_error }}</div>
        <div class="ml-auto mt-3" style="color: #9F9F9F">{{ form.name_url.length }}/30</div>
      </div>
      <div>
        <div>
          <input-text-area-component :label-input="$t('short_link.create.full_url')"
                                     v-model="$v.form.full_url.$model"
                                     rows="3"
                                     required
                                     :className="message_url_error ? 'error' : ''"
                                     :state="validateState('full_url')"
                                     :require-label="checkShortLink"
                                     :placeholder="` ${$t('short_link.create.example')}: http://super-long-link.com/shorten-it`"/>
        </div>
        <div class="flex-center" style="margin-top: 0rem;">
          <div class="text-danger">{{ message_url_error }}</div>
        </div>
      </div>
      <b-row class="mr-1 mt-3" align-h="between">
        <b-col>
          <select-component v-model="form.domain" required :options="domain_option"
                            :label-select="$t('short_link.create.domain')" text-field="text"/>
        </b-col>
        <b-col align-self="center" class="text-end">
          <div class="float-right mt-2">
            <b-button variant="primary" @click="createUrlTracking" :disabled="$v.form.$invalid || !form.domain">
              <i class="fas fa-save"/>
              {{ $t('button.create') }}
              <b-spinner small v-show="is_loading"/>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import InputComponent from "@/components/common/inputComponent.vue";
import InputTextAreaComponent from "@/components/common/textareaComponent.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import shortLinkApi from "@/repository/shortLinkApi";
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import authApi from "@/repository/authApi";
import {store} from "@/store";
import functionsCommon from "@/common/functions";

export default {
  name: "createShortUrl",
  components: {SelectComponent, InputTextAreaComponent, InputComponent},
  mixins: [validationMixin],
  props: {},
  watch: {
    form: {
      handler: function (val) {
        if (val.domain) {
          this.group_id = this.domain_option.find(item => item.value === val.domain).group_id
          this.brand_id = this.domain_option.find(item => item.value === val.domain).brand_id
        }
      },
      deep: true
    }
  },
  data() {
    return {
      form: {
        name_url: "",
        full_url: "",
        domain: null
      },
      group_id: "",
      brand_id: "",
      customer_id: "",
      message_name_error: "",
      message_url_error: "",
      domain_option: [
        // {text: "Domain.1", value: "Domain-1"},
        // {text: "Domain.2", value: "Domain-2"},
      ],
      is_loading: false
    }
  },
  validations: {
    form: {
      full_url: {
        required,
      },
      name_url: {
        required,
      }
    },
  },
  created() {
    this.getMasterDomainList()
  },
  computed: {
    checkShortLink() {
      let errors = []
      if (!_.isNil(this.validateState('full_url'))) {
        !this.$v.form.full_url.required && errors.push(this.$t('common.requiredField'))
        return errors[0]
      }
      return errors[0]
    }
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    createUrlTracking() {
      this.is_loading = true
      this.checkFormat()
      const form = {
        group_id: this.group_id,
        brand_id: this.brand_id,
        customer_id: this.customer_id,
        title: this.form.name_url,
        domain: this.form.domain,
        main_link: this.form.full_url,
      }
      shortLinkApi.createUrlTracking(form).then(resp => {
        if (resp) {
          this.closePopup()
          alert.addNewSuccess(this)
          this.returnSuccess(resp)
        }
      }).catch(err => {
        if (err.response?.data?.errors?.code == '004') {
          alert.addNewFailed(this, err.response?.data?.errors?.debugs)
          this.returnFailed(err.response?.data?.errors)
        } else {
          if (err?.response.status !== 401) {
            alert.addNewFailed(this, err.response?.data?.message)
          }
        }

      }).finally(() => {
        this.is_loading = false
      })
    },
    closePopup() {
      this.$bvModal.hide('create-short-url')
      this.group_id = "",
          this.brand_id = "",
          this.customer_id = "",
          this.clearState()
      this.$v.form.$reset()
    },
    checkFormat() {
      let urlSyntax = null
      const url = this.form.full_url;
      if (url.split('://').length > 1) {
        urlSyntax = new URL(url);
      } else {
        urlSyntax = new URL('https://' + url);
      }
      this.form.full_url = urlSyntax.href
    },
    getMasterDomainList() {
      shortLinkApi.getMasterDomain().then(resp => {
        const optional = []
        for (const item of resp?.data) {
          optional.push({
            text: item.domain,
            value: item.domain,
            _id: item._id,
            group_id: item.group_id,
            brand_id: item.brand_id,
          })
        }
        this.domain_option = optional
      }).catch(err => {
        throw err
      })
    },
    returnSuccess(res) {
      this.$emit('success', res)
      this.clearState()
      this.getCustomerProfile();
    },
    returnFailed(res) {
      this.message_name_error = ""
      this.message_url_error = ""
      switch (res.code) {
        case '004':
          console.warn(res.debugs)
          this.message_name_error = this.$t('short_link.create.error_duplicate');
          break
        case '1006':
          console.warn(res.debugs)
          this.message_url_error = this.$t('short_link.create.error_url_format');
          break
      }
    },
    getCustomerProfile() {
      authApi.getCustomerProfile(true).then(resp => {
        if (resp?.codeSYS === '001') {
          // const total = resp?.sms_unit ? resp?.sms_unit : 0
          // store.dispatch('profile/setCredit', total)
          store.dispatch('profile/setProfileImg', resp?.image)
          // const url_tracking = resp?.url_tracking ? resp?.url_tracking : 0
          // store.dispatch('profile/setURLTracking', url_tracking)
          store.dispatch('profile/setName', resp?.first_name + " " + resp?.last_name)
        }
      }).catch(err => {
        throw err
      })
    },
    clearState() {
      this.form = {
        name_url: "",
        full_url: "",
        domain: null
      }
      this.message_name_error = ""
      this.message_url_error = ""
    }
  }

}
</script>
<style scoped>

</style>