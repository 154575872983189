<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(message)="data">
            <div>
              {{$t('contacts.import.totalContact') }}:
              <span class="text-blue">{{ data.item.total}}</span>
            </div>
            <div>
              {{$t('contacts.import.validContact') }}:
              <span class="text-success">{{ data.item.total_valid}}</span>
            </div>
            <div>
              {{$t('contacts.import.invalidContact') }}:
              <span class="text-danger">{{ data.item.total_invalid}}</span>
            </div>
            <div>
              {{$t('contacts.import.duplicate') }}:
              <span class="text-warning">{{ data.item.total_duplicate ? data.item.total_duplicate : 0}}</span>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import contactGroupApi from "@/repository/contactGroupApi";
import _ from "lodash";
import masterData from "@/common/masterData.json"
import functionsCommon from "@/common/functions"
import alert from "@/common/alert";
import EmptyTable from "@/components/common/emptyTable.vue";


export default {
  name: "importContactHistory",
  components: {EmptyTable},
  props: ['group_id'],
  data: () => ({
    form: {
      $skip: 1,
      $limit: 100,
      contact_group_id: ''
    },
    isBusy: false,
    fields: [
      {
        key: "createdAt",
        label: "contacts.groupList.submitted",
      },
      {
        key: "status",
        label: "contacts.groupList.status",
      },
      {
        key: "message",
        label: "contacts.groupList.message",
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    statusList: masterData.activeList,
  }),
  mounted() {
    this.findContactHistory()
  },
  methods: {
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    findContactHistory() {
      this.isBusy = !this.isBusy
      this.form.contact_group_id = this.group_id
      contactGroupApi.findContactHistory(_.cloneDeep(this.form)).then(response => {
        this.totalRows = response.total
        this.items = response.data
      }).catch(err => {
        // alert.findFailed(this, err)
      }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },

  }
};
</script>

