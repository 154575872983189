<template>
  <b-row>
    <b-col sm="12" lg="7" md="7">
      <div>

        <b-card no-body class="border mb-3">
          <import-file-campaign @output-import="doneProcess"/>
        </b-card>
      </div>
    </b-col>
    <b-col sm="12" lg="5" md="5">
      <h5>{{ $t('campaigns.usingFileFrom.expFile') }}</h5>
      <b-button-group class="mt-1 mb-3">
        <b-button variant="primary" v-for="(item, index) in example" @click="exampleFile(item.value)" :key="index">
          {{ item.text }}
        </b-button>
      </b-button-group>
      <div class="mt-3" style="color:#e74c3c">
        <div class="mb-1" style="font-weight: 500;">{{ $t('campaigns.usingFileFrom.noteCSV.title') }}</div>
        <div class="ml-2" v-html="$t('campaigns.usingFileFrom.noteCSV.detail')"></div>
      </div>
      <div class="mt-4" v-html="$t('campaigns.usingFileFrom.note')"></div>
      <div class="exp-template">
        <img src="@/assets/messageImage.jpg" alt="example"/>
      </div>
    </b-col>

  </b-row>
</template>

<script>
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import UploadFileComponent from "@/components/common/uploadFileComponent";
import smsApi from "@/repository/smsApi";
import {required} from "vuelidate/lib/validators";
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import moment from "moment";
import ImportFileCampaign from "@/views/sms/importFile";
import TestUpload from "@/views/sms/testUpload";

export default {
  name: "sendUsingFile",
  components: {TestUpload, ImportFileCampaign, UploadFileComponent, SelectComponent, InputComponent},
  mixins: [validationMixin],
  data() {
    return {
      is_loading: false,
      baseURL: '/api',
      example: [
        {
          is_loading: false,
          value: 'xls',
          text: '.XLS'
        },
        {
          is_loading: false,
          value: 'csv',
          text: '.CSV'
        },
        {
          is_loading: false,
          value: 'xlsx',
          text: '.XLSX'
        },
        {
          is_loading: false,
          value: 'txt',
          text: '.TXT'
        }
      ],
    }
  },
  methods: {
    exampleFile(type) {
      if (type === 'txt') {
        window.open(this.baseURL + '/resource/example-campaign-txt')
      } else {
        smsApi.exampleFileDownload(type).then(response => {
          if (response?.codeSYS === '001') {
            const link = document.createElement("a");
            link.href = response.file_url;
            link.download = 'example-send-using-file.' + type;
            link.click();
          }
        }).catch(err => {
          throw err
        })
      }
    },
    doneProcess() {
      this.$router.push('Reports/Campaigns').catch(() => {
      })
    },
  },
}
</script>

<style scoped>

.exp-template {
  height: 200px;
  text-align: center;
  background-color: #C8EBFC52;
  border-radius: 12px;
  border: dashed;
  position: relative;
}

.exp-template > img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: 12px;
}
</style>