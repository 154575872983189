<template>
  <b-modal
      id="modal-copy-contact-group"
      ok-variant="info"
      :title="$t('contacts.create.createContactGroup')"
      hide-footer
      no-close-on-backdrop
  >
    <label>What would you like to name your Plan?</label>
    <input-component class="mt-2" v-model="from.groupName"
                     :label-input="$t('contacts.create.groupName')"></input-component>
    <b-row align-h="end" class="mr-1 mt-3">
      <b-button class=" mr-1" variant="danger"> {{ $t('button.cancel') }}
      </b-button>
      <b-button variant="primary"> {{ $t('button.copy') }}
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import UploadFileComponent from "@/components/common/uploadFileComponent";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";


export default {
  name: "copyContactGroup",
  components: {SelectComponent, InputComponent, InputTextAreaComponent, FormWizard, TabContent, UploadFileComponent},
  data() {
    return {
      from: {
        groupName: '',
        originator: '',
      }
    }
  },
  methods: {
    onComplete() {

    }
  }
}
</script>

<style scoped>

</style>