<template>
  <b-modal id="tracking-two-pro-modal" :title="$t('short_link.create.title')" hide-footer no-close-on-backdrop
           no-close-on-esc
           size="lg" v-if="checkPermission('create', 'action', 'url-tracking-premium')">
    <template #modal-header="{ close }">
      <h5>Tracking2Pro</h5>
      <i class="fas fa-times hover-icon action-button" @click="closePopup()"></i>
    </template>
    <div>
      <hr style="margin-top: 0;">
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <div class="full-tracking-container">
            <input-component :label-input="$t('short_link.create.full_url')"
                             required
                             class-name="tracking-input"
                             max="50"
                             v-model="$v.full_url.$model"
                             :require-label="validateState('full_url') !== null && !$v.full_url.required  ? $t('common.requiredField') : ''"
                             :state="validateState('full_url')"
                             :placeholder="`${$t('common.exemple')}: http://super-long-link.com/shorten-it`"/>
          </div>
        </b-col>
        <b-col lg="12" md="12" sm="12" class="mt-3">
          <div class="detail-container">
            <p style="font-size: 16px" class="m-0">{{ $t('invoice.detail') }}</p>
            <p class="m-0 detail-container__description">{{ $t('trackingTwoPro.condition.link') }}</p>
            <ul style="padding-left: 32px; margin: 0">
              <li>{{ $t('trackingTwoPro.attach') }}</li>
              <li>{{ $t('calendar.campaign') }}</li>
            </ul>
            <p class="m-0 detail-container__description">{{ $t('trackingTwoPro.trackingTitle') }}</p>
            <ul style="padding-left: 32px; margin: 0">
              <li>{{ $t('trackingTwoPro.campaign') }}</li>
            </ul>
            <p class="m-0 text-danger detail-container__note">{{ $t('trackingTwoPro.condition.fullURL') }}</p>
          </div>
        </b-col>
        <b-col lg="12" md="12" sm="12" class="mt-3 mb-3">
          <div class="domain-container">
            <div class="w-50">
              <select-component info :label-select="$t('short_link.create.domain')" class="m-0"
                                :options="domain_option"
                                v-model="domain"
                                required
                                text-field="text">
              </select-component>
            </div>
            <div class="w-50 btn-container">
              <button type="button" class="btn waves-effect waves-light btn-gradient"
                      style="width: 160px; padding: 6px 12px; margin-bottom: 21px" @click="creatTrackingURL"
                      :disabled="checkCreateTracking">
                {{ $t('button.create') }}
                <b-spinner small v-show="is_loading"/>
              </button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>

</template>
<script>
import SelectFilterComponent from "@/components/common/selectFilterComponent.vue";
import inputComponentSearch from "@/components/common/inputComponentSearch.vue";
import SelectComponent from "@/components/common/selectComponent.vue";
import shortLinkApi from "@/repository/shortLinkApi";
import {validationMixin} from "vuelidate";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import functionsCommon from "@/common/functions";
import InputComponent from "@/components/common/inputComponentSearch.vue";
import functions from "@/common/functions";

export default {
  name: 'tackingTwoPro',
  mixins: [validationMixin],
  components: {InputComponent, SelectComponent, SelectFilterComponent, inputComponentSearch},
  data() {
    return {
      full_url: '',
      invalidURL: false,
      is_loading: false,
      domain: null,
      domain_option: null,
    }
  },
  validations: {
    full_url: {required},
  },
  mounted() {
    this.getMasterDomainList()
  },
  computed: {
    checkCreateTracking() {
      return this.$v.full_url.$invalid || _.isNil(this.domain)
    },
  },
  methods: {
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    routeToPackage() {
      const current_plan_id = localStorage.getItem('current_plan_id')
      this.$router.push(`/Subscriptions/Package?current=${current_plan_id}`)
    },
    validateState(valid) {
      const {$dirty, $error} = this.$v[valid];
      return $dirty ? !$error : null;
    },
    checkFormat() {
      let urlSyntax = null
      const url = this.full_url;
      if (url.split('://').length > 1) {
        urlSyntax = new URL(url);
      } else {
        urlSyntax = new URL('https://' + url);
      }
      this.full_url = urlSyntax.href
    },
    creatTrackingURL() {
      this.is_loading = true
      this.checkFormat()
      const data = {
        domain: this.domain,
        main_link: this.full_url
      }
      shortLinkApi.createUrlTrackingTwoPro(data).then((res) => {
        if (res) {
          const data = {
            id: res?._id ? res?._id : null,
            domain: this.domain,
            main_link: res?.main_link ? res?.main_link : null,
            trackingUsed: 1
          }
          this.$emit('urlTackingTwoPro', data)
          this.$swal.fire({
            icon: 'success',
            title: this.$t('trackingTwoPro.modal.successURL'),
            showConfirmButton: false,
            timer: 1500
          })
        }
      }).catch((err) => {
        this.$swal.fire({
          icon: 'error',
          title: this.$t('trackingTwoPro.modal.failURL'),
          html: '<p>' + this.$t('common.somethingWrong') + '</p>' + err?.response?.data?.message,
          confirmButtonColor: '#3085d6',
        })
      }).finally(() => {
        this.is_loading = false
        this.clearData()
        this.$bvModal.hide('tracking-two-pro-modal')
      })
    },
    clearData() {
      this.full_url = ''
      this.invalidURL = false
      this.is_loading = false
      this.domain = null
      this.$v.full_url.$reset()
    },
    closePopup() {
      if (this.full_url.length > 0) {
        this.$swal.fire({
          icon: 'warning',
          title: this.$t('trackingTwoPro.modal.warningUnSuccess'),
          text: this.$t('trackingTwoPro.modal.subWarningUnSuccess'),
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#EA5455',
          cancelButtonColor: '#7367F0',
          cancelButtonText: this.$t('button.cancel'),
          confirmButtonText: this.$t('button.confirm'),
          allowOutsideClick: false,
          customClass: {
            title: 'text-danger'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.$bvModal.hide('tracking-two-pro-modal')
            this.clearData()
          }
        })
      } else {
        this.$bvModal.hide('tracking-two-pro-modal')
        this.clearData()
      }
    },
    getMasterDomainList() {
      shortLinkApi.getMasterDomain().then(resp => {
        this.domain_option = resp?.data.map(item => ({
          text: item.domain,
          value: item.domain,
          _id: item._id,
          group_id: item.group_id,
          brand_id: item.brand_id,
        }))
      }).catch(err => {
        throw err
      })
    },
  }
}

</script>
<style lang="scss">
#tracking-two-pro-modal-info-tooltip {
  margin-left: 4px !important;

  .arrow {
    display: none;
  }

  .tooltip-inner {
    border-radius: 5px;
    background: white;
    border: 1px solid #F6F6F6;
    max-width: 100% !important;
  }
}

#tracking-two-pro-modal {
  .modal-header {
    padding: 24px !important;
    padding-bottom: 16px !important;
  }

  .modal-body {
    padding: 0px 24px 0px 24px !important;
  }

  .full-tracking-container {
    position: relative;
    border-radius: 20px;
    border: 1px solid #E9E9E9;
    padding: 1.5rem  1.5rem 2.5rem 1.5rem;
  }

  .detail-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .domain-container {
    display: flex;
    justify-content: space-between;

    .btn-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .tracking-input {
    position: relative!important;
  }
}
</style>
