<template>
  <b-modal
      id="modal-create-blacklist"
      size="xl"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{$t('contacts.blacklist.create.text')}}</h5>
      <i class="fas fa-times hover-icon" @click="clearData()"></i>
    </template>
    <b-card no-body class="border">
      <div class="m-4">
        <div class="mb-4">
          <input-text-area-component
              :label-input="$t('contacts.blacklist.create.pasteNumber')"
              rows="5"
              v-model="$v.form.phone_numbers.$model"
              @focusout="replaceManualInput(form.phone_numbers)"
              :state="validateState('phone_numbers') && !telInvalid"
              require
              :require-label="
              validateState('phone_numbers') !== null &&
              !$v.form.phone_numbers.required
                ? $t('common.requiredField')
                : telInvalid
                ? telInvalidString
                : ''
            "
          />
          <label class="label-for-input text-secondary mt-3">
            {{ $t('contacts.blacklist.create.chooseDelimiter') }}
          </label>
          <div class="mb-3">
            <b-button-group class="m-2">
              <b-button v-for="(item, index) in options"
                        :key="index"
                        @click="delimiterType(item.value)"
                        :variant="item.value === form.delimiter ? 'primary' : 'outline'">
                {{item.text}}
              </b-button>
            </b-button-group>
          </div>
          <input-component
              :label-input="$t('contacts.blacklist.create.reason')"
              v-model="$v.form.reason.$model"
              required
              :state="validateState('reason')"
              :require-label="
              validateState('reason') !== null && !$v.form.reason.required
                ? $t('common.requiredField')
                : ''
            "
          />
        </div>
      </div>
    </b-card>
    <b-row align-h="end" class="mr-1 mt-3">
      <b-button
          variant="warning"
          class="mr-2"
          :disabled="$v.form.phone_numbers.$invalid"
          @click="clearData"
      >
        <i class="fas fa-retweet"/>
        {{ $t('common.resetSearch') }}
      </b-button>
      <b-button
          variant="primary"
          :disabled="$v.form.$invalid || telInvalid"
          @click="createBlacklist"
      >
        <i class="fas fa-save"/>
        {{ $t('button.save') }}
      </b-button>
    </b-row>
  </b-modal>
</template>

<script>
import InputComponent from '@/components/common/inputComponent'
import SelectComponent from '@/components/common/selectComponent'
import InputTextAreaComponent from '@/components/common/textareaComponent'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import securityApi from '@/repository/securityApi'
import alert from '@/common/alert'
import blacklistApi from "@/repository/blacklistApi";
import functionCommon from "@/common/functions"

export default {
  name: 'createBlacklist',
  components: {InputTextAreaComponent, SelectComponent, InputComponent},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        phone_numbers: '',
        reason: '',
        delimiter: 'comma',
      },
      options: [
        {value: 'comma', text: ', ' + this.$t('symbol.comma')},
        {value: 'semicolon', text: '; ' + this.$t('symbol.semicolon')},
        {value: 'bar', text: '| ' + this.$t('symbol.bar')},
        {value: 'tab', text: this.$t('symbol.tab')},
        {value: 'new_line', text: this.$t('symbol.newLine')},
      ],
    }
  },
  validations: {
    form: {
      phone_numbers: {
        required,
      },
      delimiter: {
        required,
      },
      reason: {
        required,
      },
    },
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username]
      return $dirty ? !$error : null
    },
    delimiterType(e) {
      this.form.delimiter = e
    },
    createBlacklist() {
      blacklistApi
          .createBlacklist(this.form)
          .then((response) => {
            if (response?.codeSYS === '001') {
              this.$emit('output', 'success')
              this.$bvModal.hide('modal-create-blacklist')
              this.clearData()
              alert.addNewSuccess(this)
            } else {
              alert.addNewFailed(this, response.message)
            }
          })
          .catch((err) => {
            alert.addNewFailed(this, err.response.data.message)
          })
    },
    clearData() {
      const data = {
        phone_numbers: '',
        reason: '',
        delimiter: 'comma',
      }
      this.form = data
      this.$v.form.$reset()
      this.$bvModal.hide('modal-create-blacklist')
    },
    checkNumber(number) {
      return functionCommon.telState(number)
    },
    replaceManualInput(msg) {
      let regExp = /\d+/g;
      if (regExp.test(msg)) {
        let trim = msg.trim()
        let nameArr = trim.match(regExp);
        let findDuplicate = nameArr.filter(function (value, index, array) {
          return array.indexOf(value) === index && value !== '';
        })
        let delimiter = ''
        if (this.form.delimiter === 'comma') delimiter = ','
        else if (this.form.delimiter === 'semicolon') delimiter = ';'
        else if (this.form.delimiter === 'bar') delimiter = '|'
        else if (this.form.delimiter === 'tab') delimiter = ' '
        else if (this.form.delimiter === 'new_line') delimiter = '\n'
        this.form.phone_numbers = findDuplicate.join(delimiter)
      } else {
        this.form.phone_numbers = ''
      }

    },
  },
  computed: {
    telInvalid() {
      let spliter = ''
      if (this.form.delimiter === 'comma') spliter = ','
      else if (this.form.delimiter === 'semicolon') spliter = ';'
      else if (this.form.delimiter === 'tab') spliter = ' '
      else if (this.form.delimiter === 'bar') spliter = '|'
      else if (this.form.delimiter === 'new_line') spliter = '\n'

      let telNum = _.split(this.form.phone_numbers, spliter)
      let isInvalid = false
      telNum.forEach((number) => {
        let n = _.trim(number)
        if (!this.checkNumber(n)) {
          isInvalid = true
        }
      })
      return isInvalid
    },
    telInvalidString() {
      const reg = /^[0-9]*((-|\s)*[0-9])*$/g
      let spliter = ''
      if (this.form.delimiter === 'comma') spliter = ','
      else if (this.form.delimiter === 'semicolon') spliter = ';'
      else if (this.form.delimiter === 'tab') spliter = ' '
      else if (this.form.delimiter === 'bar') spliter = '|'
      else if (this.form.delimiter === 'new_line') spliter = '\n'
      let telNum = _.split(this.form.phone_numbers, spliter)
      let alertString = ''
      telNum.forEach((number) => {
        let n = _.trim(number)
        functionCommon.telInvalid(n)
        if (n.length !== 0) {
          if (!n.match(reg)) alertString = this.$t('common.telNumOnly')
          if (n.charAt(0) !== '0' && n.charAt(0) !== '6') {
            alertString = this.$t('common.telStart')
          } else if ((n.charAt(1) !== '6' && n.charAt(1) !== '8' && n.charAt(1) !== '9')) {
            alertString = this.$t('common.telStart')
          } else if (n.length < 10) {
            alertString = this.$t('common.telLength')
          } else if (n.length === 11 && (n.charAt(0) !== '6' && n.charAt(1) !== '6')) {
            alertString = this.$t('common.telStart66')
          } else if ((n.charAt(0) === '6' && n.charAt(1) === '6') && n.charAt(2) !== '6' && n.charAt(2) !== '8' && n.charAt(2) !== '9') {
            alertString = this.$t('common.telFormat')
          }
        }
      })
      return alertString
    },
  },
  watch: {
    'form.delimiter'(val) {
      if (val) {
        this.replaceManualInput(this.form.phone_numbers)
      }
    }
  },
}
</script>


