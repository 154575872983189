<template>
  <b-modal
      id="modal-create-contact-group"
      size="lg"
      ok-variant="info"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{ $t('contacts.create.createContactGroup') }}</h5>
      <i class="fas fa-times hover-icon action-button" @click="clearData()"></i>
    </template>
    <b-card no-body class="border">
      <div class="m-4">
        <input-component :label-input="$t('contacts.create.groupName')"
                         v-model="$v.form.name.$model" required
                         :state="validateState('name')"
                         :require-label="validateState('name') !== null && !$v.form.name.required  ? $t('common.requiredField') : ''"
        />
        <b-row align-h="end" class="mr-1 mt-3">
          <b-button variant="primary"
                    :disabled="$v.form.$invalid  || is_loading"
                    @click="createContactGroup">
            <i class="fas fa-save"/> {{ $t('button.save') }}
            <b-spinner small v-if="is_loading"></b-spinner>
          </b-button>
        </b-row>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
import UploadFileComponent from "@/components/common/uploadFileComponent";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {required} from "vuelidate/lib/validators";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import contactGroupApi from "@/repository/contactGroupApi";
import {validationMixin} from "vuelidate";
import alert from "@/common/alert";
import masterData from "@/common/masterData.json";
import functions from "@/common/functions";

export default {
  name: "createContactGroup",
  components: {SelectComponent, InputComponent, InputTextAreaComponent, FormWizard, TabContent, UploadFileComponent},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        send_welcome_sms: 0,
        unsubscribe_notification: 0,
      },
      is_loading: false,
    }
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  mounted() {
    const perm = this.checkPermission('create', 'action', 'contact-groups')
    const hash = this.$route.hash
    if (hash === '#modal-create-contact-group' && perm) {
      this.$bvModal.show('modal-create-contact-group')
    }
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    createContactGroup() {
      this.is_loading = true
      contactGroupApi.create(this.form).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.clearData()
          alert.addNewSuccess(this)
        } else {
          alert.addNewFailed(this, response.message)
        }
      }).catch(err => {
        alert.addNewFailed(this, err.response?.data?.message)
      }).finally(() => {
        this.is_loading = false
      })
    },
    clearData() {
      const data = {
        name: "",
        send_welcome_sms: 0,
        unsubscribe_notification: 0,
      }
      this.form = data
      this.$v.form.$reset()
      this.$bvModal.hide('modal-create-contact-group')
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
}
</script>

<style scoped>

</style>