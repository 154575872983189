var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-create-blacklist","size":"xl","hide-footer":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('h5',[_vm._v(_vm._s(_vm.$t('contacts.blacklist.create.text')))]),_c('i',{staticClass:"fas fa-times hover-icon",on:{"click":function($event){return _vm.clearData()}}})]}}])},[_c('b-card',{staticClass:"border",attrs:{"no-body":""}},[_c('div',{staticClass:"m-4"},[_c('div',{staticClass:"mb-4"},[_c('input-text-area-component',{attrs:{"label-input":_vm.$t('contacts.blacklist.create.pasteNumber'),"rows":"5","state":_vm.validateState('phone_numbers') && !_vm.telInvalid,"require":"","require-label":_vm.validateState('phone_numbers') !== null &&
            !_vm.$v.form.phone_numbers.required
              ? _vm.$t('common.requiredField')
              : _vm.telInvalid
              ? _vm.telInvalidString
              : ''},on:{"focusout":function($event){return _vm.replaceManualInput(_vm.form.phone_numbers)}},model:{value:(_vm.$v.form.phone_numbers.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.phone_numbers, "$model", $$v)},expression:"$v.form.phone_numbers.$model"}}),_c('label',{staticClass:"label-for-input text-secondary mt-3"},[_vm._v(" "+_vm._s(_vm.$t('contacts.blacklist.create.chooseDelimiter'))+" ")]),_c('div',{staticClass:"mb-3"},[_c('b-button-group',{staticClass:"m-2"},_vm._l((_vm.options),function(item,index){return _c('b-button',{key:index,attrs:{"variant":item.value === _vm.form.delimiter ? 'primary' : 'outline'},on:{"click":function($event){return _vm.delimiterType(item.value)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1),_c('input-component',{attrs:{"label-input":_vm.$t('contacts.blacklist.create.reason'),"required":"","state":_vm.validateState('reason'),"require-label":_vm.validateState('reason') !== null && !_vm.$v.form.reason.required
              ? _vm.$t('common.requiredField')
              : ''},model:{value:(_vm.$v.form.reason.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.reason, "$model", $$v)},expression:"$v.form.reason.$model"}})],1)])]),_c('b-row',{staticClass:"mr-1 mt-3",attrs:{"align-h":"end"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"warning","disabled":_vm.$v.form.phone_numbers.$invalid},on:{"click":_vm.clearData}},[_c('i',{staticClass:"fas fa-retweet"}),_vm._v(" "+_vm._s(_vm.$t('common.resetSearch'))+" ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.$v.form.$invalid || _vm.telInvalid},on:{"click":_vm.createBlacklist}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }