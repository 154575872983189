<template>
  <div>
    <input type="file" ref="fileInput" @change="uploadFile"/>
    <progress :value="uploadProgress" max="100"></progress>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'testUpload',
  data() {
    return {
      uploadProgress: 0
    }
  },
  methods: {
    async uploadFile() {
      const file = this.$refs.fileInput.files[0]
      const chunkSize = 1024 * 1024 // 1MB chunk sizes
      let offset = 0

      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize)

        // send the chunk to your server
        const response = await axios.post('http://localhost:3000/upload', chunk, {
          onUploadProgress: progressEvent => {
            this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          }
        })

        offset += chunkSize
      }
    }
  }
}
</script>