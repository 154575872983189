import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/contact/group'
export default {
    findContactGroupAll(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    findContactHistory(search) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`contact-import-history/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    viewContact(data) {
        return service.apiAuth.post(`${resourcePath}/${data.group_id}/search/${data.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    create(data) {
        return service.apiAuth.post(`${resourcePath}/create`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    createContact(data) {
        const mapBody = {
            phone: Number(data?.phone),
            first_name: data?.first_name,
            last_name: data?.last_name,
        }
        return service.apiAuth.post(`${resourcePath}/${data.group_id}/store`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getGroupById(id) {
        return service.apiAuth.get(`${resourcePath}/get/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    delete(id) {
        return service.apiAuth.delete(`${resourcePath}/delete/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    update(id, data) {
        return service.apiAuth.patch(`${resourcePath}/update/${id}`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    updateContact(data) {
        const mapBody = {
            phone: parseInt(data?.phone),
            first_name: data?.first_name,
            last_name: data?.last_name,
        }
        return service.apiAuth.patch(`${resourcePath}/${data.group_id}/update/${data.uid}`, mapBody)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    activeStatus(id) {
        return service.apiAuth.patch(`${resourcePath}/active/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    deleteContact(data) {
        return service.apiAuth.delete(`${resourcePath}/${data.group_id}/delete/${data.uid}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },


}