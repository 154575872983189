<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4"
              v-if="checkPermission('list', 'action', 'contact-groups')|| checkPermission('list', 'action', 'contacts')">
        <b-row>
          <b-col align="end">
            {{ $t('contacts.groupList.max_contact_list') }} :
            <label class="text-green text-bold">
              {{
                max_contact_list === -1 ? $t('common.Unlimited') : max_contact_list ? max_contact_list.toLocaleString()
                    : 0
              }}
            </label><br/>
            {{ $t('contacts.groupList.max_contact_per_list') }} :
            <label class="text-green text-bold">
              {{
                max_contact_per_list === -1 ? $t('common.Unlimited') : max_contact_per_list ?
                    max_contact_per_list.toLocaleString() : 0
              }}
            </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" md="3" sm="4">
            <b-form-group
                label-cols="12"
                :label="$t('contacts.groupList.name')"
                label-for="input-sm"
            >
              <b-form-input v-model="form.name"/>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="3" sm="4">
            <select-filter-component
                :label-select="$t('contacts.groupList.status')"
                :options="statusList"
                v-model="form.status"
            />
          </b-col>
          <b-col style="margin-top: 35px">
            <button
                type="button"
                class="btn waves-effect waves-light btn-gradient"
                @click="findContactGroup"
            >
              {{ $t("common.Search") }}
            </button>
            <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
              {{ $t('common.resetSearch') }}
            </button>
          </b-col>

        </b-row>
        <hr/>
        <b-row align-v="center" class="pb-3">
          <b-col>
            <div class="d-flex align-items-center">
              <span class="mr-3">{{ $t('common.perPage') }}</span>
              <div>
                <b-form-select
                    v-model="form.$limit"
                    :options="pageOptions"
                    size="sm"
                    @change="findContactGroup"
                />
              </div>
              <div class="ml-3">
                <total-list-component :total="totalRows"/>
              </div>
            </div>
          </b-col>
          <b-col lg="4" md="4" sm="4" align="end">
            <b-button variant="primary" v-b-modal.modal-create-contact-group
                      :disabled="!checkPermission('create', 'action', 'contact-groups')">
              <i class="fas fa-plus-circle"></i> {{ $t("button.addNew") }}
            </b-button>
          </b-col>
        </b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(contacts)="data">
            <label class="text-primary text-bold">{{ changeNumberFormat(data.item.total_contact) }}</label>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
          <template #cell(status)="data">
            <switch-component v-model="data.item.status"
                              @input="activeStatus(data.item._id,data.item.status)"
                              v-if="checkPermission('update-status', 'action', 'contact-groups')"/>
          </template>
          <template #cell(import_status)="data">
            <b-badge pill class="px-2 text-white badge"
                     v-if="data?.item?.import_status && mapStatus(data.item.import_status).text !== null"
                     :class="mapStatus(data.item.import_status).color">
              {{ mapStatus(data.item.import_status).text }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <i style="color: #0d73d9" @click="addContact(data.item._id)" class="action-button icon-plus mr-2"
               v-if="checkPermission('create', 'action', 'contacts')"/>
            <i style="color: #7367f0" @click="viewDetail(data.item._id)" class="action-button icon-note mr-2"
               v-if="checkPermission('update-info', 'action', 'contact-groups') || checkPermission('list', 'action', 'contacts')"/>
            <!--            <i v-b-modal:modal-copy-contact-group style="color: #28c76f" class="action-button icon-docs mr-2 "></i>-->
            <i style="color: #DF3F1E" class="action-button icon-trash " @click="deleteContact(data.item._id)"
               v-if="checkPermission('delete', 'action', 'contact-groups')"/>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <div class="ml-auto">
      <b-pagination
          v-model="form.$skip"
          :total-rows="totalRows"
          :per-page="form.$limit"
          align="fill"
          class="my-0"
          size="sm"
          @input="findContactGroup"
      />
    </div>
    <b-modal
        id="modal-manual-create-contact"
        size="lg"
        ok-variant="info"
        hide-footer
        no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5>{{ $t('contacts.create.createContact') }}</h5>
        <i class="fas fa-times hover-icon action-button" @click="addContactSuccess"></i>
      </template>
      <manual-create-contact @output="addContactSuccess" :group_id="group_id"/>
    </b-modal>
    <create-contact-group @output="findContactGroup"/>
    <view-contact-group @output="findContactGroup" :group_id="group_id"/>
    <!--    <copy-contact-group @output="findContactGroup"/>-->
  </b-row>
</template>

<script>
import CreateContact from "@/views/contacts/contact/create";
import CreateContactGroup from "@/views/contacts/contactGroup/create";
import ViewContactGroup from "@/views/contacts/contactGroup/view";
import CopyContactGroup from "@/views/contacts/contactGroup/copy";
import contactGroupApi from "@/repository/contactGroupApi";
import _ from "lodash";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import masterData from "@/common/masterData.json"
import alert from "@/common/alert";
import SwitchComponent from "@/components/common/switchComponent";
import ManualCreateContact from "@/views/contacts/contact/manualCreate";
import contactApi from "@/repository/contactApi";
import functions from "@/common/functions";
import subscriptionApi from "@/repository/subscriptionApi";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "contactGroupList",
  data: () => ({
    totalAll: 0,
    form: {
      name: '',
      status: null,
      $skip: 1,
      $limit: 10,
    },
    max_contact_list: 0,
    max_contact_per_list: 0,
    group_id: '',
    fields: [
      {
        key: "name",
        label: "contacts.groupList.name",
      },
      {
        key: "contacts",
        label: "contacts.groupList.contacts",
      },
      {
        key: "createdAt",
        label: "contacts.groupList.createdAt",
      },
      {
        key: "import_status",
        label: "contacts.groupList.import_status",
      },
      {
        key: "status",
        label: "contacts.groupList.status",
      },
      {
        key: "actions",
        label: "contacts.groupList.actions",
      },
    ],
    isBusy: false,
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    statusList: masterData.activeList,
  }),
  components: {
    TotalListComponent,
    EmptyTable,
    ManualCreateContact,
    SwitchComponent,
    SelectFilterComponent, CopyContactGroup, ViewContactGroup, CreateContactGroup, CreateContact
  },
  mounted() {
    this.findContactGroup()
    this.getCurrentPackageDetail()
  },
  methods: {
    mapStatus(data) {
      switch (data) {
        case 'QUEUE':
          return {
            text: this.$t('common.queue'),
            color: 'bg-gray'
          }
        case 'PROCESS':
          return {
            text: this.$t('common.process'),
            color: 'bg-warning'
          }
        case 'SUCCESS':
          return {
            text: this.$t('common.success'),
            color: 'bg-success'
          }
        case 'FAIL':
          return {
            text: this.$t('common.fail'),
            color: 'bg-danger'
          }
        default :
          return {
            text: data,
            color: null
          }
      }
    },
    getCurrentPackageDetail() {
      subscriptionApi.getCurrentPackageDetail().then(resp => {
        this.max_contact_list = resp.options?.max_contact_list
        this.max_contact_per_list = resp.options?.max_contact_per_list
      })
    },
    addContactSuccess() {
      this.$bvModal.hide('modal-manual-create-contact')
      this.findContactGroup()
    },
    addContact(id) {
      this.group_id = id
      this.$bvModal.show('modal-manual-create-contact')
    },
    viewDetail(id) {
      this.group_id = id
      this.$bvModal.show('modal-view-contact-group')
    },
    changeDateFormat(date) {
      return date ? functions.changeDateFormat(date) : '-'
    },
    changeNumberFormat(data) {
      return data ? functions.changeNumberFormat(data) : '0'
    },
    findContactGroup() {
      this.isBusy = !this.isBusy
      contactGroupApi.findContactGroupAll(_.cloneDeep(this.form)).then(response => {
        this.totalAll = response.total
        this.totalRows = response.total
        this.items = response.data
      }).finally(() => {
        this.isBusy = !this.isBusy
        this.$emit('output', true)
      })
    },
    resetSearch() {
      this.form = {
        name: '',
        status: null,
        $skip: 1,
        $limit: 10,
      }
      this.findContactGroup()
    },
    activeStatus(id) {
      this.$emit('is-loading', true)
      contactGroupApi.activeStatus(id).then(response => {
        if (response?.codeSYS === '001') {
          alert.updateSuccess(this)
        } else {
          alert.updateFailed(this, response.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err.response.data.message)
      }).finally(() => {
        this.$emit('is-loading', false)
        this.$emit('output', true)
      })
    },
    deleteContact(id) {
      this.$swal
          .fire({
            icon: 'warning',
            title: this.$t('common.delete.topic'),
            text: this.$t('common.delete.detail'),
            showCancelButton: true,
            confirmButtonColor: '#EA5455',
            cancelButtonColor: '#7367F0',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.yes'),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$emit('is-loading', true)
              contactGroupApi.delete(id).then((response) => {
                if (response?.codeSYS === '001') {
                  alert.deleteSuccess(this)
                  this.findContactGroup()
                } else {
                  alert.deleteFailed(this, response.message)
                }
              }).catch((err) => {
                alert.deleteFailed(this, err.response.data.message)
              }).finally(() => {
                this.$emit('is-loading', false)
              })
            }
          })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>

