import service from './index'
import functionsCommon from "@/common/functions";

const resourcePath = '/blacklist'
export default {
    findBlacklistAll(search = {}) {
        if (search.$skip) {
            search.$skip = (search.$skip - 1) * search.$limit
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(search))
        const str = queryString.toString()
        return service.apiAuth.get(`${resourcePath}/list?${str}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    createBlacklist(data) {
        return service.apiAuth.post(`${resourcePath}/create`, data)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    deleteBlacklist(id) {
        return service.apiAuth.delete(`${resourcePath}/delete/${id}`)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },


}