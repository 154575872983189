<template>
  <div>
    <div class="mb-3">
      <overlay-page :visible="is_loading" :rounded="false" :opacity="0.9">
        <b-card>
          <div class="title-box">
            {{ $t('contacts.topic') }}
          </div>
          <div>
            <b-row>
              <b-col sm="12" md="12" lg="4" class="mt-2">
                <div>
                  <b-card class="credit-balance">
                    <b-row>
                      <b-col sm="12" md="12" lg="3" class="mt-2">
                        <img src="@/assets/images/icon/ic_contact_group.svg" alt="credit" width="80">
                      </b-col>
                      <b-col sm="12" md="12" lg="8" class="mt-2">
                        <div>
                          {{ $t('contacts.menu.contactGroup') }} ( {{ $t('common.active') }} )
                        </div>
                        <div class="credit-total">
                          {{ changeFormatNumber(contact_groups) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="4" class="mt-2">
                <div>
                  <b-card class="credit-balance-box">
                    <b-row>
                      <b-col sm="12" md="12" lg="3" class="mt-2">
                        <img src="@/assets/images/icon/ic_contact.svg" alt="credit" width="80">
                      </b-col>
                      <b-col sm="12" md="12" lg="8" class="mt-2">
                        <div>
                          {{ $t('contacts.totalContact') }} ( {{ $t('common.active') }} )
                        </div>
                        <div class="credit-total">
                          {{ changeFormatNumber(contacts) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="4" class="mt-2">
                <div>
                  <b-card class="blacklist-box">
                    <b-row>
                      <b-col sm="12" md="12" lg="3" class="mt-2">
                        <img src="@/assets/images/icon/ic_backlist.svg" alt="credit" width="80">
                      </b-col>
                      <b-col sm="12" md="12" lg="8" class="mt-2">
                        <div>
                          {{ $t('contacts.menu.blackList') }}
                        </div>
                        <div class="credit-total">
                          {{ changeFormatNumber(blacklists) }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </overlay-page>
    </div>
    <b-row>
      <b-col sm="3" md="3" lg="3">
        <b-card class="mb-1 send-box-bg" border-variant="primary" v-for="menu in menuList" :key="menu.key"
                @click="activeMenu(menu.key)" :class="menuSelected === menu.key ?'active' :''"
                v-if="checkPermission(menu.permissions, 'tab')">
          <b-row>
            <b-col cols="4">
              <div>
                <i :class="`${menu.imgAction} display-4 `"></i>
              </div>
            </b-col>
            <b-col cols="8">
              <h4 class="card-title">{{ $t(menu.text) }}</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="9" md="9" lg="9">
        <b-card v-if="menuSelected !=='' ">
          <contact-group-list v-if="menuSelected === 'contactGroup'" @output="updateFunc($event)"
                              @is-loading="setIsLoading($event)"/>
          <contact-list v-if="menuSelected === 'contacts'" @output="updateFunc($event)"/>
          <black-list v-if="menuSelected === 'blackList'" @output="updateFunc($event)"  @is-loading="setIsLoading($event)"/>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import QuickSend from "@/views/sms/quickSend";
import CampaignBuilder from "@/views/sms/campaignBuilder";
import SendUsingFile from "@/views/sms/sendUsingFile";
import ContactList from "@/views/contacts/contact/index";
import BlackList from "@/views/contacts/blackList/list";
import ContactGroupList from "@/views/contacts/contactGroup/list";
import TopicComponent from "@/components/common/topicComponent";
import functions from "@/common/functions";
import contactApi from "@/repository/contactApi";
import OverlayPage from "@/components/common/overlayPage.vue";

export default {
  name: "index",
  components: {
    OverlayPage,
    TopicComponent, ContactGroupList, BlackList, ContactList, SendUsingFile, CampaignBuilder, QuickSend
  },
  data() {
    return {
      menuList: [
        {
          key: 'contactGroup',
          text: 'contacts.menu.contactGroup',
          imgAction: 'icon-people',
          permissions: 'contact-groups contacts'
        },
        {key: 'blackList', text: 'contacts.menu.blackList', imgAction: 'icon-user-unfollow', permissions: 'blacklists'},
      ],
      menuSelected: null,
      contacts: 0,
      contact_groups: 0,
      blacklists: 0,
      is_loading: false
    }
  },
  mounted() {
    this.filterPermission()
    this.getContactSummary()
  },
  methods: {
    activeMenu(menu) {
      this.menuSelected = menu
    },
    checkPermission(permission, type) {
      return functions.userCheckPermission(permission, type)
    },
    filterPermission() {
      this.menuSelected = _.filter(this.menuList, item => {
        let per = this.checkPermission(item?.permissions, 'tab');
        return item?.permissions.includes(per?.module)

      })[0].key
    },
    getContactSummary() {
      contactApi.getContactSummaryDetail().then(resp => {
        if (!!resp) {
          this.contacts = resp?.contacts
          this.contact_groups = resp?.contact_groups
          this.blacklists = resp?.blacklists
        }
      }).catch(err => {
        throw err
      }).finally(() => {
        this.is_loading = false
      })
    },
    changeFormatNumber(number) {
      let isNumber = Number(number)
      return isNumber?.toLocaleString()
    },
    updateFunc(e) {
      if (e) {
        this.getContactSummary()
      }
    },
    setIsLoading(e) {
      this.is_loading = e
    }
  }

}
</script>
<style scoped>
.title-box {
  color: #5C698C;
  font-size: 24px;
  font-weight: 700;
}

.title-box-content {
  color: #5C698C;
  font-size: 20px;
  font-weight: 700;
}

.credit-balance {
  border-radius: 12px;
  background: #ECFFFB;
  //background-color: fade(var(--primary-color), 1%);

  .credit-total {
    color: #5C698C;
    font-size: 36px;
    font-weight: 700;
  }
}

.credit-balance-box {
  border-radius: 12px;
  background: #F8F9FE;
  //background-color: fade(var(--primary-color), 1%);

  .credit-total {
    color: #5C698C;
    font-size: 36px;
    font-weight: 700;
  }
}

.blacklist-box {
  border-radius: 12px;
  background: #FFF5F5;
  //background-color: fade(var(--primary-color), 1%);

  .credit-total {
    color: #5C698C;
    font-size: 36px;
    font-weight: 700;
  }
}


</style>