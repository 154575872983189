<template>
  <b-modal
      id="modal-view-contact-group"
      size="xl"
      ok-variant="info"
      title=""
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{ $t('contacts.view.updateContactGroup') }}</h5>
      <i class="fas fa-times hover-icon action-button" @click="clearData()"></i>
    </template>
    <b-card no-body class="border">
      <b-tabs card justified>
        <b-tab active v-if="checkPermission('update-info', 'action', 'contact-groups')">
          <template #title>
            {{ $t('contacts.create.setting') }} <i class="ml-1 fas fa-cogs"></i>
          </template>
          <b-card-text>
            <b-card no-body>
              <div class="m-4">
                <input-component class="mt-2"
                                 :label-input="$t('contacts.create.groupName')"
                                 v-model="$v.form.name.$model" required
                                 :state="validateState('name')"
                                 :require-label="validateState('name') !== null && !$v.form.name.required  ? $t('common.requiredField') : ''"

                />
                <b-row align-h="end" class="mr-1 mt-3">
                  <b-button variant="primary"
                            :disabled="$v.form.$invalid || is_loading"
                            @click="updateContactGroup">
                    <i class="fas fa-save"/> {{ $t('button.save') }}
                    <b-spinner small v-if="is_loading"></b-spinner>
                  </b-button>
                </b-row>
              </div>
            </b-card>

          </b-card-text>
        </b-tab>
        <b-tab v-if="checkPermission('list', 'action', 'contacts')">
          <template #title>
            {{ $t('contacts.groupList.contacts') }} <i class="ml-1 fas fa-users"></i>
          </template>
          <b-card-text>
            <contact :group_id="group_id" @output="getContactGroupById" :process="process"/>
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            {{ $t('contacts.create.importHistory') }} <i class="ml-1 far fa-clock"></i>
          </template>
          <b-card-text>
            <import-contact-history :group_id="group_id"/>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-modal>
</template>

<script>
import UploadFileComponent from "@/components/common/uploadFileComponent";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import InputComponent from "@/components/common/inputComponent";
import SelectComponent from "@/components/common/selectComponent";
import Contact from "@/views/contacts/contact";
import contactGroupApi from "@/repository/contactGroupApi";
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import ImportContactHistory from "@/views/contacts/contact/importHistory";
import functions from "@/common/functions";


export default {
  name: "viewContactGroup",
  components: {
    ImportContactHistory,
    Contact,
    SelectComponent, InputComponent, InputTextAreaComponent, UploadFileComponent
  },
  mixins: [validationMixin],
  props: ['group_id'],
  data() {
    return {
      form: {
        name: "",
        send_welcome_sms: 0,
        unsubscribe_notification: 0,
      },
      is_loading: false,
      process: '',
    }
  },
  created() {
    this.$watch('group_id', (val) => {
      if (val) {
        this.getContactGroupById()
      }
    })
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    getContactGroupById() {
      contactGroupApi.getGroupById(this.group_id).then(resp => {
        this.form.name = resp.name
        this.form.send_welcome_sms = resp.send_welcome_sms
        this.form.unsubscribe_notification = resp.unsubscribe_notification
        this.process = resp.import_status
      }).catch(err => {
        throw err
      })
    },
    updateContactGroup() {
      this.is_loading = true
      const data = _.cloneDeep(this.form)
      contactGroupApi.update(this.group_id, data).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.$bvModal.hide('modal-view-contact-group')
          alert.updateSuccess(this)
        } else {
          alert.updateFailed(this, response.message)
        }
      }).catch(err => {
        alert.updateFailed(this, err.response?.data?.message)
      }).finally(() => {
        this.is_loading = false
      })
    },
    clearData() {
      this.$emit('output', 'success');
      this.$bvModal.hide('modal-view-contact-group')
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }

  }
}
</script>

<style scoped>

</style>

