<template>
  <div>
    <b-row class="align-items-center mb-1">
      <b-col sm="12" md="12" lg="4" class="text-left text-md-right">
        <label class="fw-medium mb-0 "> {{ label }} </label>
      </b-col>
      <b-col sm="12" md="12" lg="8">
        <b-form-input type="text"
                      name=""
                      :id="id"
                      autocomplete="off"
                      v-model="name"
                      @input="updateValue"
                      :state="state"
        ></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    inputType: {
      type: [String, Boolean],
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    required: {
      type: String,
      default: "",
    },
  },
  computed: {
    state() {
      if (this.required) {
        return this.name.length >= 1
      }
    },
  },
  data() {
    return {
      name: ''
    }
  },
  methods: {
    updateValue(e) {
      if (this.inputType === 'custom_message') {
        const prevent = ['{otp}', '{validity}', '{refcode}'];
        const isEvery = prevent.every(item => e.includes(item));
        this.$emit('preventCustomOTPPattern', {isEvery, e})
        this.$emit('modelValue', e)
      } else {
        this.$emit('modelValue', e)
      }
    }
  }
};
</script>

<style>
</style>