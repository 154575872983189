<template>
  <div>
    <h5>{{ $t('common.preview') }}</h5>
    <b-row align-h="center">
      <div style="width: 230px;">
        <b-card
            overlay
            img-src="@/assets/iPhone14ProMax_message.png"
            img-alt="Card Image"
            class="message-card"
        >
          <b-row align-h="center">
            <label class="sender">{{ senderName }}</label>
          </b-row>
          <b-row align-h="end" class="fixHeight">
            <span class="preview-message">
             <pre style="margin-bottom: 0px!important;">{{ message }}</pre>
            </span>
          </b-row>
        </b-card>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "preview",
  props: {
    senderName: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    perMessage: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      messageData: [],
    }
  },
  watch: {
    message(newVal) {
      let noOfValue = newVal?.length;
      let countMessage = noOfValue / this.perMessage;
      this.messageData = []
      for (let i = 0; i < countMessage; i++) {
        let result = newVal.substring(this.perMessage * i, (this.perMessage * (i + 1)) - 1);
        if (this.messageData.length < i) {
          this.messageData.push(result)
        } else {
          this.messageData[i] = result
        }
      }
    }
  }
}
</script>

<style>
.sender {
  font-family: "Prompt";
  margin-top: 43px;
  font-size: 10px;
  color: #586A8F !important;
  height: 13px;
}

.preview-message {
  color: #586A8F !important;
  font-family: "Prompt";
  margin-top: 1.5% !important;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #F9F9F9;
  font-size: 12px;
  padding: 5px !important;
  max-width: 140px;
  border-radius: 8px !important;
}

pre {
  font-family: "Prompt";
  color: #586A8F !important;
  font-size: 12px;
  overflow: unset !important;
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
}

.fixHeight {
  max-height: 315px;
  overflow: auto;
}
</style>