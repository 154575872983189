import service from './index'
import functionsCommon from "@/common/functions";
import moment from "moment";

const resourcePath = '/url-tracking'
export default {
    exportLink(data) {
        if (data?.$skip) {
            data.$skip = (data.$skip - 1) * data.$limit
        }
        if (data?.date_time) {
            data.date_from = data?.date_time[0];
            data.date_to = data?.date_time[1];
        }
        data.date_time = null;
        data.main_link = data?.url_link_type === 'short-url' ? null : data?.url_link;
        data.short_link = data?.url_link_type === 'full-url' ? null : data?.url_link;
        data.url_link_type = null;
        data.url_link = null;
        data.file_type = "csv";
        const filter = functionsCommon.removeEmptyKey(data)
        return service.apiAuth.post(`${resourcePath}/link-export`, filter)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    getList(data) {
        if (data?.$skip) {
            data.$skip = (data.$skip - 1) * data.$limit
        }
        if (data?.date_time) {
            data.date_from = data?.date_time[0];
            data.date_to = data?.date_time[1];
        }
        data.date_time = null;
        data.main_link = data?.url_link_type === 'short-url' ? null : data?.url_link;
        data.short_link = data?.url_link_type === 'full-url' ? null : data?.url_link;
        data.url_link_type = null;
        data.url_link = null;
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(data)
        );
        const str = queryString.toString();
        return service.apiAuth.get(`${resourcePath}/list?${str}`).then(resp => {
            return resp.data
        }).catch(err => {
            throw err
        })
    },
    getUrlTrackingList(data) {
        if (data?.$skip) {
            data.$skip = (data.$skip - 1) * data.$limit
        }
        let date_from = moment(data?.date_time[0]).format('YYYY-MM-DD') + 'T00:00:00'
        let date_to = moment(data?.date_time[1]).format('YYYY-MM-DD') + 'T23:59:59'
        const filter = {
            date_from: date_from,
            date_to: date_to,
            ref_id: data?.ref_id || null,
        }
        const queryString = new URLSearchParams(
            functionsCommon.removeEmptyKey(filter)
        );
        const str = queryString.toString();
        return service.apiAuth.get(`${resourcePath}/daily-transaction-list?${str}`).then(resp => {
            return resp.data
        }).catch(err => {
            throw err
        })
    },
    deleteShortLink(id) {
        return service.apiAuth.delete(`${resourcePath}/delete/${id}`).then(resp => {
            return resp.data
        }).catch(err => {
            throw err
        })
    },
    createUrlTracking(data) {
        const mapData = {
            group_id: data.group_id || null,
            brand_id: data.brand_id || null,
            customer_id: data.customer_id || null,
            title: data.title || null,
            domain: data.domain || null,
            main_link: data.main_link || null,
        }
        return service.apiAuth.post(`${resourcePath}/create`, mapData).then(resp => {
            return resp.data
        }).catch(err => {
            throw err
        })
    },
    getMasterDomain() {
        return service.apiAuth.get(`${resourcePath}/master-domain-list`).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    },
    exportDailyTransaction(data) {
        const bodyQuery = new URLSearchParams(
            functionsCommon.removeEmptyKey(data)
        );
        const body = bodyQuery.toString();
        return service.apiAuth.post(`${resourcePath}/daily-transaction-export`, body)
            .then(resp => {
                return resp.data
            }).catch(err => {
                throw err
            })
    },
    createUrlTrackingTwoPro(data) {
        return service.apiAuth.post(`${resourcePath}/full-link-create`, data).then(resp => {
            return resp?.data
        }).catch(err => {
            throw err
        })
    }
}