<template>
  <form-wizard
      :color="secondary_color"
      :title="null"
      :subtitle="null"
      step-size="xs"
  >
    <tab-content :title="$t('contacts.create.importFile')" icon="fas fa-upload" class="ml-2 mr-2">
      <input-component :label-input="$t('campaigns.usingFileFrom.name')" v-model="$v.form.name.$model"
                       :state="validateState($v.form.name)" required/>
      <select-component :label-select="$t('setting.senderID.form.senderID')"
                        :textRightRouter="checkPermission('list', 'action', 'sender-names') ? 'setting.requestNew' : null"
                        :pathRightRouter="checkPermission('create', 'action', 'sender-names') ? '/Setting/SenderID/Create-SenderName' : null"
                        v-model="$v.form.sender_name.$model" required :options="originatorList" text-field="text"
                        :state="validateState($v.form.sender_name)"
                        :require-label="senderHasBlocked && !form.sender_name ? $t('retarget.senderHasBlocked') : ''
                      "/>
      <div class="mb-2">
        <label class="pt-2 pl-2">{{ $t('campaigns.campaignFrom.scheduleCampaign.subtitle') }}</label>
        <div>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                id="radio-group-2"
                v-model="form.schedule"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
            >
              <b-form-radio v-for="(item, index) in options_send_type" :value="item.value"
                            :key="index">{{ $t(item.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div v-if="form.schedule">
          <b-row>
            <b-col lg="6" sm="12">
              <input-component :label-input="$t('campaigns.campaignFrom.scheduleCampaign.date')"
                               v-model="form.schedule_date" type="date" :min-date="minDate"/>
            </b-col>
            <b-col lg="6" sm="12">
              <input-component :label-input="$t('campaigns.campaignFrom.scheduleCampaign.time')"
                               v-model="form.schedule_time" type="time"/>
            </b-col>
          </b-row>
          <label v-if="sendBeforeNow" class="ml-2 text-danger"> **** {{
            $t('campaigns.campaignFrom.selectedAfterNow')
            }}</label>
        </div>
      </div>
      <upload-file-campaign-component ref="uploadFileCampaignComponent" @path_file="mapPath"
                                      @retargetObject="retargetObject" @output="showResult" remark-file/>
      <div v-show="originResult.length > 0">
        <div class="mt-3">
          <label class="text-bold text-blue">{{ $t('common.exemple') }}</label>
          <hr class="m-0"/>
        </div>
        <b-form-checkbox v-model="form.is_head" @input="containsHeader" class="mt-2 mb-2">
          {{ $t('campaigns.usingFileFrom.fileContainsHeaderRow') }}
        </b-form-checkbox>
        <div style="overflow-x:auto;" align="center" v-if="form.path_file">
          <table>
            <tr v-for="rowItem in previewList">
              <td style="min-width: 200px" class="preview-box" v-for="Item in rowItem">
                {{ Item }}
              </td>
            </tr>
            <tr v-if="previewList.length > 0">
              <td class="preview-box" v-for="(Item, key , index) in previewHead" :key="key">
                <b-form-select
                    :value=null
                    ref="inputRef"
                    @change="selectedKey(index, key , $event)"
                    :options="headList"
                >
                  <option :value=null>{{ selectOption }}</option>
                </b-form-select>
              </td>
            </tr>
          </table>
        </div>
        <div style="background-color: #C8EBFC52; border-radius: 10px" class="mb-2 mt-2 p-2">
          <b-row align-h="center">
            <b-button-group class="m-2">
              <b-button @click="messageType(true)" size="sm"
                        :variant="form.use_message_file ? 'primary' : 'outline-primary'">
                {{ $t('campaigns.usingFileFrom.diffMessage') }}
              </b-button>
              <b-button @click="messageType(false)" size="sm"
                        :variant="!form.use_message_file ? 'primary' : 'outline-primary'">
                {{ $t('campaigns.usingFileFrom.oneMessage') }}
              </b-button>
            </b-button-group>
          </b-row>
          <div v-if="!form.use_message_file" class="m-2">
            <input-message-component :label-input="$t('campaigns.quickSendFrom.message')" rows="5"
                                     parentComponent="usingFile"
                                     v-model="form.message" require-tracking @trackingTwoPro="trackingTwoPro"/>
          </div>
        </div>
      </div>
      <label class="text-danger">{{ $t('campaigns.campaignFrom.remark') }}</label>
    </tab-content>
    <tab-content :title="$t('contacts.create.validate')" icon="fas fa-table" class="ml-2 mr-2">
      <b-row class=" mb-2" align-h="center">
        <b-col
            class="mb-1 pr-1 "
            lg="6" sm="12" md="12"
            v-for="(totals, index) in totalImport"
            :key="index"
        >
          <div class="total-overview " :class="[totals.bgcolor]">
            <div class="d-flex align-items-center p-3">
              <div class="mr-2" style="width: 73px!important;">
                  <span class="display-3 ml-2" :class="[totals.iconcolor]">
                    <i :class="[totals.icon]" class="mt-2"></i>
                  </span>
              </div>
              <div>
                <h5 :class="[totals.textcolor]" class="text"> {{ $t(totals.text) }}</h5>
                <h3 class="font-medium mb-0">{{ totals.total }}</h3>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mt-3">
        <label class="text-bold text-blue">{{ $t('common.exemple') }}</label>
      </div>
      <b-row>
        <b-table
            responsive
            class="mb-0"
            :items="sample_data"
            :fields="fields"
            :empty-text="$t('empty')"
            show-empty>
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
        </b-table>
      </b-row>
    </tab-content>
    <tab-content :title="$t('contacts.create.success')" icon="far fa-check-circle " class="ml-2 mr-2">
      <div align="center" v-if="success&&step_success">
        <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
        <h3 v-html="$t('success')" class="mb-3"></h3>
      </div>
      <div align="center" v-if="!success&&step_success">
        <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
        <h3 v-html="$t('failed')" class="mb-3"></h3>
        <label class="text-danger">{{ errorMessage }}</label>
      </div>
      <div align="center" v-if="!success&&!step_success">
        <b-spinner
            class="mt-5 mb-1"
            style="width: 4rem; height: 4rem"
            label="Large Spinner"
            variant="warning"
            type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
    </tab-content>
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left">
        <b-button :disabled="success&&step_success" v-if="props.activeTabIndex > 0" @click="props.prevTab()">
          <i class="fas fa-chevron-left"/>
          {{ $t('common.previous') }}
        </b-button>
      </div>
      <div class="wizard-footer-right" v-if="checkPermission('create', 'action', 'send-using-file')">
        <b-button v-if="!props.isLastStep" @click="nextPage(props)" variant="gradient"
                  class="wizard-footer-right width-btn"
                  :disabled="$v.form.$invalid || trackingErr ||is_loading_next_step || sendBeforeNow || (props.activeTabIndex === 1 && total_valid === 0) || (props.activeTabIndex === 0 && rate_limit_send !== 0)">
          {{
          props.activeTabIndex === 1 ? $t('button.send') : rate_limit_send !== 0 && props.activeTabIndex === 0 ? '' :
          $t('common.next')
          }}

          <div v-if="rate_limit_send !== 0 && props.activeTabIndex === 0">
            Resend in {{ rate_limit_send }} sec.
          </div>
          <div v-else class="d-inline">
            <b-spinner small label="Small Spinner" v-if="is_loading_next_step"></b-spinner>
            <i class="fas fa-chevron-right" v-else/>
          </div>
        </b-button>
        <b-button v-else @click="isLastStep(props)"
                  variant="gradient"
                  :disabled="is_loading_last_step && success" class="wizard-footer-right width-btn">
          {{ props.isLastStep ? $t('common.done') : $t('common.next') }}
          <b-spinner small label="Small Spinner" v-if="is_loading_last_step"></b-spinner>
          <i class="fas fa-chevron-right" v-else/>
        </b-button>
      </div>
    </template>
  </form-wizard>
</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ManualCreateContact from "@/views/contacts/contact/manualCreate";
import SelectComponent from "@/components/common/selectComponent";
import InputComponent from "@/components/common/inputComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import _ from "lodash";
import alert from "@/common/alert";
import UploadFileCampaignComponent from "@/components/common/uploadFileCampaignComponent";
import smsApi from "@/repository/smsApi";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import moment from "moment";
import InputMessageComponent from "@/components/common/inputMessageComponent";
import functions from "@/common/functions";
import {mapState} from 'vuex'
import functionsCommon from "@/common/functions";

export default {
  name: "importFileCampaign",
  components: {
    InputMessageComponent,
    UploadFileCampaignComponent,
    ManualCreateContact,
    SelectComponent, InputComponent, InputTextAreaComponent, FormWizard, TabContent
  },
  mixins: [validationMixin],
  data() {
    return {
      trackingErr: false,
      trackingTwoProId: null,
      senderHasBlocked: false,
      status: null,
      success: true,
      step_success: true,
      minDate: moment(new Date()).format("YYYY-MM-DD"),
      form: {
        use_message_file: true,
        name: '',
        sender_name: null,
        path_file: '',
        is_head: false,
        phone_index: '',
        message_index: '',
        message: '',
        schedule: false,
        schedule_date: '',
        schedule_time: '',
      },
      cid: "",
      testResult: {},
      originalFile: [],
      displayTable: false,
      resultList: [],
      headList: [],
      originResult: [],
      is_next_step: false,
      is_loading_last_step: false,
      is_loading_next_step: false,
      errorMessage: '',
      total_valid: null,
      previewHead: [],
      tagList: [
        {value: "phone_index", text: this.$t('campaigns.usingFileFrom.phone')},
        {value: "message_index", text: this.$t('campaigns.usingFileFrom.message')},
      ],
      sample_data: [],
      fields: [
        {
          key: "phone",
          label: "campaigns.usingFileFrom.phone",
        },
        {
          key: "message",
          label: "campaigns.usingFileFrom.message",
        },
      ],
      totalImport: [
        {
          bgcolor: "total-overview-purple",
          iconcolor: "",
          icon: "fas fa-users",
          textcolor: "text-gray",
          text: "contacts.import.totalContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-green",
          iconcolor: "",
          icon: "fas fa-check-circle",
          textcolor: "text-gray",
          text: "contacts.import.validContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-warning",
          iconcolor: "",
          icon: "fas fa-minus-circle",
          textcolor: "text-gray",
          text: "contacts.import.blockContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-red",
          iconcolor: "",
          icon: "far fa-times-circle",
          textcolor: "text-gray",
          text: "contacts.import.invalidContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-gray",
          iconcolor: "",
          icon: "far fa-thumbs-down",
          textcolor: "text-gray",
          text: "contacts.import.spamContact",
          total: "0",
        },
        {
          bgcolor: "total-overview-blue",
          iconcolor: "",
          icon: "far fa-clone",
          textcolor: "text-gray",
          text: "contacts.import.duplicate",
          total: "0",
        }
      ],
      rate_limit_send: 0,
      options_send_type: [
        {text: 'campaigns.campaignFrom.send_type_now', value: false},
        {text: 'campaigns.campaignFrom.scheduleCampaign.text', value: true},
      ],
      is_index_send_type: 0
    }
  },
  validations: {
    form: {
      name: {required},
      sender_name: {required},
      phone_index: {required}
    }
  },
  computed: {
    ...mapState({
      secondary_color: state => state.theme.secondaryColor,
    }),
    BrandSetting() {
      return JSON.parse(localStorage.getItem('brand_setting'))
    },
    selectOption() {
      this.headList = [
        {value: "phone_index", text: this.$t('campaigns.usingFileFrom.phone')},
        {value: "message_index", text: this.$t('campaigns.usingFileFrom.message')},
      ]
      return this.$t("common.pleaseSelectOption")
    },
    previewList() {
      const data = _.chunk(Object.values(this.resultList), 5)
      return data[0] ? data[0] : []
    },
    sendBeforeNow() {
      if (this.form.schedule) {
        if (this.form.schedule_date === '' || this.form.schedule_time === '') {
          return true
        } else {
          return new Date().getTime() > new Date(this.form.schedule_date + 'T' + this.form.schedule_time).getTime()
        }
      } else {
        return false
      }
    },
    originatorList() {
      return this.$store.getters['sender/getSenderList']
    },
    // checkHasTwoHeader() {
    //   return this.originResult.length > 0 ? this.previewList.length > 0 ? !(this.previewList[0].length > 1) : false : false
    // }
    userInfo () {
      return  this.$store.getters["profile/getProfile"]
    }
  },
  mounted() {
    this.getOriginatorList()
  },
  methods: {
    trackingTwoPro(param) {
      this.trackingTwoProId = param.id
      this.trackingErr = param.error
    },
    retargetObject(retarget) {
      this.form.use_message_file = retarget?.is_file_message
      this.form.message = retarget?.message ? retarget?.message.replace('{url-tracking}', '') : ''
      this.form.name = retarget?.campaign_name ? retarget?.campaign_name : null
      this.senderHasBlocked = false
      if (retarget?.sender_status === 'blocked') {
        this.senderHasBlocked = true
        this.form.sender_name = null
      } else if (retarget?.sender_status === 'inactive') {
        this.form.sender_name = null
      } else if (retarget?.sender_status === 'active') {
        this.form.sender_name = retarget?.sender_name ? retarget?.sender_name : null
      }
    },
    validateState(validation) {
      const {$dirty, $error} = validation;
      return $dirty ? !$error : null;
    },
    selectSendType(e, index) {
      this.is_index_send_type = _.cloneDeep(index)
    },
    getOriginatorList() {
      const data = {
        type: 'mkt',
        status: 'active',
        $limit: 50000,
        is_only_own: 0
      }
      smsApi.getOriginator(data).then(resp => {
        this.$store.dispatch('sender/setSenderList', resp)
      }).catch(err => {
        throw err
      })
    },
    messageType(data) {
      this.form.is_head = false
      this.resultList = []
      this.form.use_message_file = data
      this.form.phone_index = ''
      this.form.message_index = ''
      this.form.message = ''
      this.headList = _.cloneDeep(this.tagList)
      if (!this.form.use_message_file) {
        const data = {
          value: "message_index",
          text: this.$t('campaigns.usingFileFrom.message'),
          disabled: true
        }
        this.headList.splice(1, 1, data);
      }
      setTimeout(() =>
              this.resultList = _.cloneDeep(this.originResult)
          , 50)

    },
    onComplete() {
      this.$emit('output', 'success');
      this.$bvModal.hide('modal-create-contact')
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    containsHeader() {
      if (this.form.is_head) {
        this.originalFile = _.cloneDeep(this.resultList)
        this.resultList.shift()
      } else {
        this.resultList = this.originalFile
      }
    },
    mapPath(e) {
      this.form.path_file = e
    },
    showResult(e) {
      this.originResult = _.cloneDeep(e)
      this.total_valid = null
      this.displayTable = true
      this.resultList = e
      this.previewHead = e[0]
      this.headList = _.cloneDeep(this.tagList)
      this.form.path_file = ''
      this.form.is_head = false
      this.form.phone_index = ''
      this.form.message_index = ''
    },
    clearData() {
      const data = {
        use_message_file: true,
        name: '',
        sender_name: null,
        path_file: '',
        is_head: false,
        phone_index: '',
        message_index: '',
        message: '',
        schedule: false,
        schedule_date: '',
        schedule_time: '',
      }
      this.form = data
      this.total_valid = null
      this.$v.form.$reset()
    },
    selectedKey(index, key, e) {
      this.total_valid = null
      let myArray = _.cloneDeep(this.headList)
      const indexValue = key.toString()
      const findDuplicate = myArray.map(e => e.value).indexOf(e);
      if (findDuplicate < 0 && e !== null) {
        let textOption = (e === "message_index" ? this.$t('campaigns.usingFileFrom.message') : this.$t('campaigns.usingFileFrom.phone'))
        const data = {
          value: e,
          text: textOption
        }
        this.headList.splice(findDuplicate, 0, data);
      } else {
        if (e !== null) {
          this.headList.splice(findDuplicate, 1);
          let textOption = (e === "message_index" ? this.$t('campaigns.usingFileFrom.message') : this.$t('campaigns.usingFileFrom.phone'))
          const data = {
            value: e,
            text: textOption,
            disabled: true
          }
          this.unDisableKey(indexValue)
          switch (e) {
            case 'phone_index':
              this.form.phone_index = indexValue;
              break;
            case 'message_index':
              this.form.message_index = indexValue;
              break;
          }
          this.headList.splice(findDuplicate, 0, data);
        } else {
          this.unDisableKey(indexValue)
        }
      }
    },
    unDisableKey(index) {
      if (!_.isUndefined(this.getObjKey(this.form, index))) {
        const disableKey = this.getObjKey(this.form, index)
        const findKey = this.headList.map(e => e.value).indexOf(disableKey);
        let textOption = (disableKey === "message_index" ? this.$t('campaigns.usingFileFrom.message') : this.$t('campaigns.usingFileFrom.phone'))
        this.headList.splice(findKey, 1);
        let data = {
          value: disableKey,
          text: textOption
        }
        this.headList.splice(findKey, 0, data);
        this.form[this.getObjKey(this.form, index)] = ''
      }
    },
    getObjKey(obj, value) {
      return Object.keys(obj).find(key => obj[key] === value);
    },
    nextPage(props) {
      if (props.activeTabIndex === 0) {
        if (this.form.phone_index === "" || (this.form.message_index === "" && this.form.use_message_file)) {
          this.$swal.fire({
            html: this.$t('campaigns.usingFileFrom.warning'),
            icon: 'info',
            confirmButtonColor: '#3085d6',
          })
        } else {
          this.is_loading_next_step = true
          this.success = null
          if (!this.form.schedule) {
            this.form.schedule_date = ''
            this.form.schedule_time = ''
          }
          smsApi.validateFile(_.cloneDeep(this.form)).then(resp => {
            const response = resp?.data
            let rate = Number(resp.headers['ratelimit-reset'])
            if (response?.codeSYS === '001') {
              let timer = rate;
              let interval = setInterval(() => {
                if (timer === 0) {
                  clearInterval(interval)
                  this.rate_limit_send = 0
                } else {
                  this.rate_limit_send = timer--
                }
              }, 1000)
              this.totalImport[0].total = response?.total
              this.totalImport[1].total = response?.total_valid
              this.totalImport[2].total = response?.total_blocked
              this.totalImport[3].total = response?.total_invalid
              this.totalImport[4].total = response?.total_spam
              this.totalImport[5].total = response?.total_duplicate
              this.sample_data = response?.sample_data
              this.total_valid = response?.total_valid
              this.cid = response?.cid
              props.nextTab()
            } else {
              let timer = rate;
              let interval = setInterval(() => {
                if (timer === 0) {
                  clearInterval(interval)
                  this.rate_limit_send = 0
                } else {
                  this.rate_limit_send = timer--
                }
              }, 1000)
              alert.addNewFailed(this, response.message)
            }
          }).catch(err => {
            let rate = Number(err.response.headers['ratelimit-reset'] || 60)
            let timer = rate;
            let interval = setInterval(() => {
              if (timer === 0) {
                clearInterval(interval)
                this.rate_limit_send = 0
              } else {
                this.rate_limit_send = timer--
              }
            }, 1000)
            if (err?.response?.status !== 429 || err?.response?.status !== 401) {
              if (err?.response?.data?.errors?.code === "420") {
                const current_plan_id = localStorage.getItem('current_plan_id')
                alert.creditIsInsufficient(this, err?.response?.data?.message, `/Subscriptions/Package?current=${current_plan_id}`)
              } else if (err?.response?.status == 502) {
                alert.sendFailed(this, this.$t('campaigns.usingFileFrom.error502message'))
                this.$refs.uploadFileCampaignComponent.removeFile()
              } else {
                alert.sendFailed(this, err?.response?.data?.message)
              }
            }
          }).finally(() => {
            this.is_loading_next_step = false
          })
        }
      } else if (props.activeTabIndex === 1) {
        if (this.userInfo?.identity_verify_status === 'verified' || this.userInfo?.is_using_trial || this.userInfo?.is_using_default) {
          this.$swal.fire({
            icon: 'warning',
            title: this.$t('common.confirmSMS.topic'),
            text: this.$t('common.confirmSMS.detail'),
            showCancelButton: true,
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.send'),
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.is_loading_next_step = true
              this.step_success = false
              const data = {
                cid: this.cid
              }
              if (!_.isNil(this.trackingTwoProId)) {
                data['use_tag_url'] = true
                data['full_link_id'] = this.trackingTwoProId
              }
              smsApi.importAdd(data).then(response => {
                if (response?.codeSYS === '001') {
                  this.step_success = true
                  this.success = true
                  props.nextTab()
                } else {
                  this.step_success = true
                  this.success = false
                }
              }).catch(err => {
                if (err.code === 'ERR_BAD_RESPONSE') {
                  let current_date = new Date()
                  const date_campaign = {
                    time1: moment(new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate())).format('YYYY-MM-DD') + 'T00:00:00',
                    time2: moment(new Date(current_date.getFullYear(), current_date.getMonth(), current_date.getDate())).format('YYYY-MM-DD') + 'T23:59:59',
                  }
                  const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(date_campaign))
                  const str = queryString.toString()
                  this.$router.push(`/Reports/Campaigns?${str}`).catch(() => {
                  })
                }
                this.errorMessage = err.response?.data?.message
                this.step_success = true
                this.success = false
              }).finally(() => {
                this.is_loading_next_step = false
              })
              props.nextTab()
            }
          })
        }else if (this.userInfo.identity_verify_status === 'waiting-approve') {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify_waiting.svg'),
            title: this.$t('common.not_been_approve'),
            text: this.$t('common.please_check_your_auth_status_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_check_your_auth_status_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.check'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/Account').catch(() => {
              })
            }
          })
        }else if (this.userInfo.identity_verify_status === 'rejected') {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify_failed.svg'),
            title: this.$t('common.not_been_success_auth'),
            text: this.$t('common.please_re_auth_enable_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_re_auth_enable_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.verify'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/Account').catch(() => {
              })
            }
          })
        } else {
          this.$swal.fire({
            imageUrl:require('@/assets/images/icon/verify.svg'),
            title: this.$t('common.has_not_been_auth'),
            text: this.$t('common.please_verify_your_credential_1') + (this.BrandSetting?.footer_text || 'SMS2PRO') + this.$t('common.please_verify_your_credential_2'),
            showCancelButton: true,
            confirmButtonColor: '#19CBAC',
            cancelButtonColor: '#5B656E',
            cancelButtonText: this.$t('button.cancel'),
            confirmButtonText: this.$t('button.verify'),
            allowOutsideClick: false,
            width:'40em',
            padding:'1.25rem'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$bvModal.show('modal-verify-account')
            }
          })
        }
      } else {
        props.nextTab()
      }
    },
    isLastStep(props) {
      if (props.isLastStep) {
        this.clearData()
        this.$emit('output-import', 'success');
      }
    },
  },
  watch: {
    'form.schedule'(newVal) {
      if (!newVal) {
        this.form.schedule_date = ''
        this.form.schedule_time = ''
      }
    },
  }
}
</script>
<style scoped>
.preview-box {
  border: 1px solid #525252;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  background-color: rgba(233, 235, 239, 0.98);
  width: 35px;
  min-height: 47px;
}

.total-overview {
  color: #fff;
  border-radius: 15px;
  background-repeat: repeat-x;
}

.wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.total-overview-green {
  background: #0064fa;
  background-image: linear-gradient(30deg, rgba(40, 199, 111, .5), #28c76f);
}

.total-overview-red {
  background: #640064;
  background-image: linear-gradient(30deg, #ea5455, rgba(234, 84, 85, .5));
}

.total-overview-warning {
  background: #ff8730;
  background-image: linear-gradient(30deg, rgb(241, 199, 70), #eeb559);
}

.total-overview-gray {
  background: rgba(180, 180, 185, 0.99);
  background-image: linear-gradient(30deg, rgb(148, 149, 155), #525252);
}

.total-overview-purple {
  background: #640064;
  background-image: linear-gradient(30deg, #7367f0, rgba(115, 103, 240, .5));
}

.total-overview-blue {
  background: #001164;
  background-image: linear-gradient(30deg, #67acf0, rgba(133, 103, 240, 0.5));
}

.display-3 {
  font-size: 2.75rem;
}

.wizard-tab-content {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* .send-type {
  background: #ffffff;
  width: 45%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ffffff;
}

.send-type.custom-control.custom-control-inline.custom-radio {
  padding: .5rem 2rem .5rem 2.5rem;
  cursor: pointer;
}

.send-type::v-deep .custom-control-label {
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.send-type::v-deep .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.send-type:hover {
  border: 1px solid var(--primary-color);
} */
</style>